const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions" style={{ color: "white" }}>
            <h3>მომსახურების პირობები და წესები</h3>
            <p className='terms-standard-text'>გთხოვთ, ვებ-გვერდის გამოყენებამდე ყურადღებით გაეცნოთ კომპანიის მომსახურების პირობებსა დაწესებს.</p>
            <p className='terms-standard-text' style={{ marginBottom: "35px" }}>ყველა მომხმარებელი, რომელსაც სურს USDELIVERY.GE-ს მომსახურებით სარგებლობა, ვალდებულია
                დაეთანხმოს და დაიცვას ქვემოთ მოყვანილი ყველა წესი და პირობა, გამონაკლისის გარეშე.
                USDELIVERY.GE-ის ადმინისტრაცია უფლებას იტოვებს, ნებისმიერ დროს ცალმხრივად, ამავე ვებ-გვერდზე
                გამოქვეყნების გზით, შეიტანოს ცვლილებები ამ პირობებში მომხმარებელის შემდგომი დამატებითი
                თანხმობის გარეშე.</p>
            <p className='terms-standard-text'>1. წინამდებარე წესებსა და პირობებზე თანხმობით, მომხმარებელი ადასტურებს, რომ:</p>
            <p className='terms-standard-text'>1.1.  არის საქართველოს კანონმდებლობის შესაბამისად დაფუძნებული იურიდიული პირი ან ფიზიკური
                პირი 18 ან მეტი წლის;</p>
            <p className='terms-standard-text'>1.2.  მის მიერ წარმოდგენილი ყველა მონაცემი არის ზუსტი;</p>
            <p className='terms-standard-text'>1.3. თანახმაა პირადი ანგარიშის გახსნის შემდეგ ხელშეკრულების შეწყვეტამდე  USDELIVERY.GE-მ
                მოიძიოს/გადაამოწმოს და დაამუშავოს მომხმარებლის მიერ მითითებული ან მასთან დაკავშირებული
                ნებისმიერი, მათ შორის პერსონალური ინფორმაცია ან/და უნივერსალური იდენტიფიკატორები.</p>
            <p className='terms-standard-text'>2. მომხმარებლის ანგარიშის გახსნა და გამოყენება:</p>
            <p className='terms-standard-text'>2.1. მომხმარებელი რეგისტრირდება ვებ-გვერდზე www.USDELIVERY.GE;</p>
            <p className='terms-standard-text'>2.2. რეგისტრაციისთვის მომხმარებელი უთითებს სახელს, გვარს, დასახელებას, პირად ნომრს,
                სიდენტიფიკაციო კოდს, საკონტაქტო მისამართს, ტელეფონის ნომერს, ელ.ფოსტას და პაროლს, რომელიც
                იცის მხოლოდ მან და სისტემა არ ინახავს მას ღია სახით, რომელიც არ არის ხელმისაწვდომი არავისთვის
                გარდა მეპატრონისა. </p>
            <p className='terms-standard-text'>3.1. მომხმარებელი კისრულობს სრულ პასუხისმგებლობას მისი ანგარიშით განხორციელებულ ნებისმიერ
                ქმედებაზე. იმ შემთხვევაში, თუ პირი სხვისი სახელითა და მითითებით ახორციელებს წვდომას
                მომხმარებლის ანგარიშზე,  იგულისხმება, რომ მას გააჩნია საკმარისი უფლებამოსილება, მომხმარებელს
                დააკისროს განსაზღვრული ვალდებულებები. კომპანია სრულად იხსნის პასუხისმგებლობას მესამე პირის
                მიერ მომხმარებლის ანგარიშზე წვდომისას დამდგარ ზიანზე. </p>
            <p className='terms-standard-text'>3.2.  მომხმარებელი ვალდებულია, უზრუნველყოს მის პირად ანგარიშზე არსებული ინფორმაციის სიზუსტე
                და მისი განახლება. USDELIVERY.GE არ იქნება პასუხისმგებელი მომხმარებლის მიერ აღნიშნული
                ვალდებულების შეუსრულებლობით გამოწვეულ არანაირ ზიანზე. USDELIVERY.GE უფლებამოსილია,
                ნებისმიერ დროს მოითხოვოს მომხმარებლისგან ინფორმაციის სისწორის დადასტურება ან დოკუმენტების
                ან სხვაგვარი მტკიცებულების წარმოდგენა; </p>
            <p className='terms-standard-text'>4. თურქული, პოლონური, ამერიკული და ბერძნული საფოსტო მისამართები</p>
            <p className='terms-standard-text'>4.1.  ჩვენს საიტზე რეგისტრაციის შემდეგ, ავტომატურად იღებთ ექაუნტს, სადაც მოცემულია თქვენი
                ინდივიდუალური საფოსტო მისამართები თურქეთში, პოლონეთში, ამერიკასა და საბერძნეთში. როდესაც
                შეიძენთ რაიმეს ონლაინ მაღაზიებში, მიუთითებთ სწორედ ამ მისამართს.</p>
            5.  ამანათების მართვის პანელი:
            <p className='terms-standard-text'>5.1.  ინდივიდუალურ საფოსტო მისამართზე  მისული გზავნილები აისახება მომხმარებლის
                პროფილში/ექაუნტში, ამანათების მართვის პანელში.  აქვე ახდენთ მისამართზე მისული ამანათების
                მონიტორინგს, მისასვლელი ამანათების დამატებას, დეკლარირებას და ტრანსპორტირების საფასურის
                დაფარვას. </p>
            <p className='terms-standard-text'>5.2. კომპანია არ არის ვალდებული ფიზიკურად ფლობდეს რომელიმე საწყობს, მას შეუძლია ისარგებლოს
                მესამე პირის საწყობთ;</p>
            <p className='terms-standard-text'>6. ტრანსპორტირების ღირებულება, წონის დამრგვალება და გადახდა </p>
            <p className='terms-standard-text'>6.1. ტრანსპორტირების ღირებულება:</p>
            <p className='terms-standard-text'>თურქეთი-დან  1 კგ ამანათის / ტვირთის სახმელეთო გადაზიდვის ღირებულება შეადგენს 3 დოლარის
                ექვივალენტს ლარში.</p>
            <p className='terms-standard-text'>პოლონეთი-დან 1 კგ ამანათის / ტვირთის სახმელეთო გადაზიდვის ღირებულება შეადგენს 6.5 ევროს
                ექვივალენტს ლარში.</p>
            <p className='terms-standard-text'>აშშ-დან 1კგ ამანათის / ტვირთის საჰაერო გადაზიდვის ღირებულება 7.8 დოლარის ექვივალენტს ლარში.
                საბერძნეთი-დან 1 კგ ამანათის / ტვირთის სახმელეთო გადაზიდვის ღირებულება 3 ევროს ექვივალენტს
                ლარში.</p>
            <p className='terms-standard-text'>• აღნიშნულ ტარიფში არ შედის ტვირთის დაზღვევა ტრანსპორტირების დროს შესაძლო დაზიანებებზე.
                შესაბამისად კომპანია არ იღებს პასუხისგებლობას გზავნილის რაიმე სახის დაზიანებაზე. </p>
            <p className='terms-standard-text'>• ტრანსპორტირების დროს ტვირთის დასაზღვევად მომხმარებელმა უნდა გვაცნობოს წინასწარ. </p>
            <p className='terms-standard-text'>• დაზღვეული ნივთი შეიფუთება დამატებით ნივთის სახეობიდან გამომდინარე. </p>
            <p className='terms-standard-text'>• შეფუთვის ხარჯებს  ფარავს მომხმარებელი.</p>
            <p className='terms-standard-text'>• თუ დაზღვეული ნივთის გაბარიტული წონა აღემატება მის ფიზიკურ წონას ტრანსპორტირება ითვლება
                ნივთის გაბარიტის მიხედვით. </p>
            <p className='terms-standard-text'>• მომხმარებელს ნივთის დაზღვევა შეუძლია ჩვენგან დამოუკიდებლადაც სადაზღვეო კომპანიაში</p>
            <p className='terms-standard-text'>*არასტანდარტული / არაგაბარიტული ტვირთის ან/და ამანთების ტრანსპორტირების პირობების
                დასაზუსტებლად, აუცილებელია დაგვიკავშირდეთ წინასწარ.</p>
            <p className='terms-standard-text'>• ტრანსპორტირებსი ტარიფი ანგარიშდება კომერციული კურსით</p>
            <p className='terms-standard-text'>6.2. გადახდა </p>
            <p className='terms-standard-text'>ტრანსპორტირების ღირებულების მომხმარებლის ექაუნტში ატვირთვა მოხდეა საწყობში მიტანისას.</p>
            <p className='terms-standard-text'>სავალდებულოა გადახდა განხორციელდეს www.usdelivery.ge მხოლოდ  ვებ–გვერდზე მითითებული
                ანგარიშსწორების ფორმებით.</p>
            <p className='terms-standard-text'>6.3 მომხმარებლის მხრიდან შპს გალაქსი ფოსტ-ის მიმართ ამანათის ტრანსპორტირების დავალიანების</p>
            <p className='terms-standard-text'>არსებობის შემთხვევაში, კომპანია იტოვებს უფლებას მომხმარებლის მხრიდან დავალიანების სრულად
                დაფარვამდე არ გასცეს მომხმარებლის სახელზე ჩამოსული სხვა გზავნილები. </p>
            <p className='terms-standard-text'>6.4 წონის დამრგვალება</p>
            <p className='terms-standard-text'>წონა მრგვალდება მხოლოდ ამერიკიდან გამოწერილ ამანათებზე. წონა მრგვალდება 0.100 გრამამდე</p>
            <p className='terms-standard-text'>6.5 მოცულობითი წონა</p>
            <p className='terms-standard-text'>იმ შემთხვევაში,თუ მოცულობითი წონა აჭარბებს რეალურს, ტრანსპორტირების ღირებულება გამოითვლება
                მოცულობითი წონით. </p>
            <p className='terms-standard-text'>მოცულობითი წონა განისაზღვრება ამანათის სიგრძე, სიგანე, სიმაღლის გადამრავლებით და 6000 ზე
                გაყოფით, სანტიმეტრი და კილოგრამი ერთეულისთვის შემდეგი ფორმულის მიხედვით: სიგრძე X სიგანე X
                სიმაღლე / 6000. დამატებით იხილეთ კალკულატორი - ს განყოფილება www.usdelivery.ge ვებ–გვერდზე.
                ნებისმიერი სხვა ამანათის ტრანსპორტირების ღირებულების დაანგარიშება ხორიელდება მხოლოდ
                ფიზიკური წონის წონის მიხედვით.</p>
            <p className='terms-standard-text'>6.6 რეგიონის ფილიალიდან ამანათების გატანის შემთხვევაში: ამანათების ტრანსპორტირების საფასურს
                დაემატება 4 ლარი.</p>
            <p className='terms-standard-text'>7. საკურიერო მომსახურება</p>
            <p className='terms-standard-text'>7.1  საკურიერო მომსახურება საფასური თბილისის მასშტაბით შეადგენ</p>
            <p className='terms-standard-text'>7.2. კურიერს ამანათი მიაქვს მომხმარებლის მიერ სისტემაში დაფიქსირებულ მისამართზე ან საკურიერო
                მომსახურების განცხადებისას მითითებულ მისამართზე და მხოლოდ ერთხელ.</p>
            <p className='terms-standard-text'>7.3. ამანათის სხვა მისამართზე მიტანის სურვილის შემთხვევაში, საჭიროა მომხმარებელმა
                www.usdelivery.ge-ის ვებ-გვერდზე, საკუთარ ექაუნთში, მისამართის გრაფაში შეცვალოს მისამართი.
                ცვლილების დაფიქსირება საჭიროა ამანათის საფასურის გადახდამდე. სხვა შემთხვევაში ცვლილება
                გათვალისწინებული არ იქნება.</p>
            <p className='terms-standard-text'>7.4. კურიერი არ აიტანს ამანათს სართულებზე.</p>
            <p className='terms-standard-text'>7.5. მისამართზე მოსვლამდე კურიერი დარეკავს მომხმარებლის გვერდზე დაფიქსირებულ მობილურის
                ნომერზე, რათა გადასცეს მომხმარებელს ამანათი. თუ დაკავშირება რამოდენიმე წუთის განმავლობაში ვერ
                მოხდა მომხმარებლის ან სატელეკომუნიკაციო კომპანიის მიზეზით, კურიერი წაიღებს ამანათს საწყობში,
                საიდანაც მომხმარებელი ვალდებულია თვითონ გაიტანოს ამანათი.</p>
            <p className='terms-standard-text'>7.6. ამანათის მისაღებად საჭიროა მომხმარებელმა თან იქონიოს მიმღების (პიროვნება, რომლის სახელი და
                გვარი აწერია უშუალოდ ამანათს) პირადობის დამადასტურებელი მოწმობა ან პასპორტი. ქსეროასლი არ
                მიიღება. აგრეთვე შესაძლებელია ნოტარიულად დამოწმებული მინდობილობის წარდგენა.</p>
            <p className='terms-standard-text'>7.7. ამანათის გადაცემის დამოწმება ხდება მომხმარებლის მიერ პირადობის ან პასპორტის წარდგენით და
                შესაბამისი ჩანაწერით მომხმარებლის ექაუნთში. </p>
            <p className='terms-standard-text'>7.8. ჩამოსული ამანათები საწყობში შეინახება 30 (ოცდა ათი) დღის განმავლობაში. თუ მომხმარებელი ვერ
                ახერხებს ამანათის გატანას მითითებულ პერიოდში, მან ამის შესახებ უნდა აცნობოს კომპანიას  ვადის

                გასვლამდე არაუგვიანეს  ერთი კვირისა ვებგვერდზე მითითებულ ელექტრონულ ფოსტაზე. მოუკითხავი
                ტვირთები 30 დღის გასვლის შემდეგ გადაეცემა სახელმწიფოს</p>
            <p className='terms-standard-text'>8. ამანათის გადაცემა მესამე პირზე შესაძლებელია მხოლოდ იმ შემთხვევაში, თუ მას შეუძლია წარადგინოს
                რომელიმე ქვემოთ ჩამოთვლილი დოკუმენტი:</p>
            <p className='terms-standard-text'>8.1. მიმღების პირადობის დამადასტურებელი მოწმობა, ან პასპორტი გადახდის ქვითართან ერთად.</p>
            <p className='terms-standard-text'>8.2. ნოტარიულად დამოწმებული მინდობილობა, ამანათის გამტანის პირადობის მოწმობა და გადახდის
                ქვითარი.</p>
            <p className='terms-standard-text'>9. ამანათები ოფისიდან გაიცემა ორშაბათიდან პარასკევის ჩათვლით 10 საათიდან 19:00 საათამდე. შაბათს
                11:00-15:00 საათამდე.</p>
            <p className='terms-standard-text'>10.  ავიარეისების განრიგი არ არის ფიქსირებული და კომპანია პასუხისმგებლობას არ იღებს
                ფორსმაჟორული სიტუაციების შემთხვევაში რეისების დაგვიანებაზე (მაგ.: რთული მეტეოროლოგიური
                პირობები, ავია კომპანიის მიერ გაუქმებული ან შეცვლილი განრიგი და ა.შ.).</p>
            <p className='terms-standard-text'>11. მისამართზე მისულად ითვლება მხოლოდ ის ამანათი, რომელსაც გააჩნია შესაბამისი მიტანის  (Delivered)
                და ჩაბარების &quot;signed by …&quot; დამადასტურებელი სტატუსი  საფოსტო  კომპანიების ვებ-გვერდებიდან, მაგ.:
                USPS, UPS, FEDEX, ROYAL MAIL. კომპანია გალაქსი ფოსტი  უფლებამოსილია მოითხოვოს
                მომხმარებლისგან ხელმოწერის დამადასტურებელი  გრაფიკული დოკუმენტი ე.წ. &quot;Signature&quot;
                (შესრულებული ფურცელზე ან ელექტრონულ მოწყობილობაზე რეალური ხელის მოწერის სახით) რაც
                საფოსტო კომპანიის მიერ (მაგ.: UPS, Fedex, USPS, ROYAL MAIL) ამანათის ჩაბარებისას იქმნება და ინახება
                საფოსტო კომპანიის ბაზაში.</p>
            <p className='terms-standard-text'>
                11.1  იმ შემთხვევაში, თუ ამანათის სტატუსი  საფოსტო კომპანიის ვებ-გვერდზე აისახება როგორც
                მიტანილი &quot;delivered&quot; და ასევე ჩანს, რომ ამანათი ჩაბარებულია &quot;signed by …&quot;,  მაგრამ  ამანათი ფიზიკურად
                არ არის მიტანილი ფოსტის მიერ  ჩვენი კომპანიის  საწყობში,  ამ შემთხვევაში, მომხმარებელი უნდა
                დაუკავშირდეს გამომგზავნს და გაასაჩივროს ამანათის მიუღებლობა.  საჩივრის განაცხადის შემდეგ,
                საფოსტო  კომპანიაში უნდა ჩაირთოს ე.წ. investigation ან Tracer, ანუ მოძიების სტატუსი, რაც ნიშნავს, რომ
                ამანათზე გამოცხადებულია ძებნა იმის დასდგენად თუ რა მისამართზეა რეალურად მიტანილი. აღნიშნული
                პროცედურების გავლა მომხმარებლისგან სავალდებულოა. კომპანია გალაქსი ფოსტი არ იღებს
                ვალდებულებას იმ ამანათებზე, რომელიც ფიზიკურად არ მისულა საწყობში, თუნდაც საფოსტო კომპანიის
                ვებ-გვერდზე არსებობდეს სტატუსი  delivered და  Signed by … სადაო სიტუაციის შემთხვევაში, კომპანია
                გალაქსი ფოსტი  და ასევე მისი პარტნიორი კომპანიები, რომელიც ოპერირებენ თურქეთი, პოლონეთი, აშშ
                და საბერძნეთი დაუდასტურებენ გამომგზავნ კომპანიას და  ფოსტას წერილობით ან ფაქსის სახით, რომ
                ამანათი ფიზიკურად არ მისულა მომხმარებლის მისამართზე.
            </p>

            <p className='terms-standard-text'>12. განბაჟება:</p>
            <p className='terms-standard-text'>12.1. თუ ამანათის ღირებულება აღემატება 300 ლარს ან 30 კილოგრამს,  ასევე თუ ამანათში  მოთავსებულია
                ერთგვაროვანი საქონელი, რომელსაც საბაჟო ჩათვლის კომერციულ საქონლად, იგი გადის უპირობო
                განბაჟების პროცედურას.</p>
            <p className='terms-standard-text'>12.2. განსაბაჟებელი ამანათის ტრანსპორტირების ღირებულების თანხას ემატება საბაჟო დეკლარაციის
                მომზადების ღირებულება 15 ლარის ოდენობით. განბაჟება ხორციელდება მომხმარებლის მიერ
                წარმოდგენილი ნივთის ინვოისის (ორდერის) საფუძველზე, გამონაკლის შემთხვევაში კომპანია გალაქსი
                ფოსტი უფლებას იტოვებს ინვოისის ავთენტურობის დადგენის მიზნით, მომხმარებელს მოსთხოვოს
                დამატებითი ინფორმაცია ( მისი ექაუნთის გახსნა, გადახდის დამადასტურებელი დოკუმენტაცია და ა.შ.),

                ზემოდხსენებული მოთხოვნის შეუსრულებლობის შემთხვევაში კომპანია უფლებას იტოვბს ამანათი
                გადასცეს შემოსამავლების სამსახურის შესაბიმს განყოფილებას შემგომი რეაგირებისათვის.</p>
            <p className='terms-standard-text'>13. დეკლარირება (პროდუქციის დასახელებისა და ფასის მითითება) გალაქსი ფოსტის ვებ–გვერდზე
                სავალდებულოა. ინფორმაციის სისწორეზე პასუხისმგებლობა მთლიანად ეკისრება მომხმარებელს.</p>
            <p className='terms-standard-text'>
                13.1. იმ შემთხვევაში, თუ საბაჟო სამსახური ან ფინანსთა სამინისტრო დააკისრებს საჯარიმო სანქციას
                კომპანია გალაქსი ფოსტს მომხმარებლის მიერ მომსახურების პირობების მთლიანად ან ნაწილობრივ
                შეუსრულებლობის გამო (მაგ.: პროდუქციის არასრული ან არაზუსტი დეკლარირება), კომპანია გალაქსი
                ფოსტი უფლებას იტოვებს აღნიშნული ჯარიმის გადახდა დააკისროს მომხმარებელს.
            </p>
            <p className='terms-standard-text'>14. იხილეთ პროდუქციის ჩამონათვალი, რომლის ტრანსპორტირებაც შეზღუდულია. მომხმარებლის
                მხრიდან აკრძალული პროდუქციის გამოგზავნის შემთხვევაში კომპანია გალაქსი ფოსტი არ იღებს
                პასუხისმგებლობას პროდუქციის საქართველოში გამოგზავნაზე ან უცხოეთში საბაჟოს მხრიდან
                პროდუქციის კონფისკაციაზე.</p>
            <p className='terms-standard-text'>14.1. იმ შემთვევაში თუ მომხმარებელი მაინც გაგზავნის აკრძალულ პროდუქციას კომპანია გალაქსი ფოტის
                მიერ მომხმარებლისთვის გადაცემულ ამერიკულ, თურქულ, პოლონურ, ან საბერძნეთის მისამართზე,
                კომპანია არ იღებს პასუხისმგებლობას ამანათის გამომგზავნ მაღაზიასთან ან კერძო პირთან ამანათის
                დაბრუნებაზე. აღნიშნული დარღვევა ჩაითვლება მომსახურების პირობების დარღვევად, რაც გამოიწვევს
                მომსახურების უპირობო შეწყვეტას და მომხმარებლისთვის საჯარიმო სანქციების დაკისრებას, რაც
                განისაზღვრება კომპანია გალაქსი ფოსტისთვის მიყენებული მატერიალური ზარალის ოდენობით.</p>
            <p className='terms-standard-text'>15. ყველა ინფორმაცია და პირობა რომელიც განთავსებულია გალაქსი ფოსტის ვებ–გვერდზე
                www.usdelivery.ge მათ შორის ტექსტური მასალა ხშირად დასმულ კითხვების განყოფილებიდან და
                მომსახურების პირობების გვერდიდან ნამდვილია და წარმოადგენს მომსახურების პირობების განუყოფელ
                ნაწილს. მომსახურების პირობები ითვლება კომპანიასა და მომხმარებელს შორის დადებულ შეთანხმებად,
                მომხმარებლის ვებ–გვერზე რეგისტრაციის მომენტიდან.</p>
            <p className='terms-standard-text'>
                16. გალაქსი ფოსტი არ იღებს პასუხისმგებლობას საქართველოში ამანათის ტრანსპორტირებაზე, თუ
                პროდუქციის გამოწერისას მომხმარებელი დაუშვებს შეცდომას და მისამართს მიუთითებს არასრულად ან
                არასწორად. მომხმარებლის მხრიდან მისამართის ცვლილება დაუშვებელია და ის უნდა ჩაიწეროს ზუსტად
                ისე, როგორც მოცემულია მომხმარებლის გვერდზე, ასევე რეგისტრაციისას მიღებულ ელექტრონულ
                წერილში.
            </p>
            <p className='terms-standard-text'>17. გალაქსი ფოსტი უფლებას იტოვებს მოახდინოს მომხმარებელთან ურთიერთობის ამსახველი
                მონაცემების ჩაწერა და დამახსოვრება (ინტერნეტის, სატელეკომუნიკაციო თუ ვიდეო სამეთვალყურეო
                სისტემების ყველა სახეობის საშუალებით, მათ შორის ელ–ფოსტით გამოგზავნილი წერილების, ჩათით ან
                ტელეფონით საუბრები,) რაც შესაძლოა კომპანიამ გამოიყენოს მისი უფლებების დაცვის მიზნით
                მომხმარებელთან სასამართლო ან სხვა დავის წარმოქმნის შემთხვევაში.</p>
            <p className='terms-standard-text'>18. მომხმარებელი ეთანხმება, რომ მის მიერ ამანათის მისაღებად წარმოდგენილი დოკუმენტები (პირადობა,
                პასპორტი, ბანკის ქვითარი) არის უტყუარი და ამ დოკუმენტების ნამდვილობაზე პასუხისმგებლობა
                მთლიანად ეკისრება მომხმარებელს.</p>
            <p className='terms-standard-text'>19. ანგარიშის დახურვა და სარგებლობის შეზღუდვა</p>
            <p className='terms-standard-text'>19.1. გალაქსი ფოსტი უფლებამოსილია მომხმარებელს ცალმხრივად შეუწყვიტოს ან შეუზღუდოს უფლება
                ისარგებლოს კომპანიის მომსახურებით თუ მიიჩნევს, რომ დაირღვა წინამდებარე წესებისა და პირობების
                მოთხოვნები, რის შესახებაც მომხმარებლს ეცნობა ელექტრონული ფოსტით. აღნიშნულ შემთხვევაში
                ანგარიშის დახურვა  ან სარგებლობის შეზღუდვა ხდება ამ მუხლით განსაზღვრული შეტყობინების
                ჩაბარების მომენტიდან;</p>

            <p className='terms-standard-text'>19.2. იმ შემთხვევაში, თუ კომპანია დახურავს ანგარიშს ან შეზღუდავს მის გამოყენებას, მომხმარებელი არ
                არის უფლებამოსილი თავისი ან სხვისი სახელით შექმნას ახალი ანგარიში.</p>
            <p className='terms-standard-text'>19.3. მომხმარებელი უფლებამოსილია ნებისმიერ დროს შეწყვიტოს USDELIVERY.GE_ს სერვისით
                სარგებლობა, რაც არ იწვევს წინამდებარე შეთანხმებით გათვალისწინებული კონფიდენციალურობის
                ვალდებულების შეწყვეტას.</p>
            <p className='terms-standard-text'>19.4. მომხმარებლის მხრიდან ნებისმიერი ტიპის ჟარგონული, არაეთიკური ან შეურაწმყოფელი ტერმინების
                გამოყენება, როგორც ელ–ფოსტით, ასევე ტელეფონზე, ჩათით ან თანამშრომლების მიმართ პირადად, ასევე
                ხმამაღალი საუბარი, ტონის აწევა, ყვირილი, დამცინავი ან ირონიული საუბარი - გამოიწვევს მომხმარებლის
                სერვისის დაუყოვნებლივ და უპირობო შეწყვეტას მისი შემდგომი განუახლებლობის პირობით.</p>
            <p className='terms-standard-text'>
                20. გალაქსი ფოსტი ვალდებულებას იღებს კლიენტთთან მხოლოდ იმ სერვისებზე, რაც აღწერილია
                მომსახურების პირობების გვერდზე. კომპანია უზრუნველყოფს მომხმარებლის წვდომას ვებ-გვერდზე და
                მომსახურების გაწევას წინამდებარე წესებისა და პირობების შესაბამისად. კომპანია არავითარ სხვა
                დაპირებასა და გარანტიას არ იძლევა მომსახურებასთან ან რაიმე პროდუქტთან ან სერვისთან
                დაკავშირებით, რომელიც შეადგენს მომსახურების ნაწილს, შესაბამისად, გამოირიცხება (კანონით
                დადგენილ ფარგლებში) კომპანიის პასუხისმგებლობა აღნიშნულთან დაკავშირებით (მათ შორის,
                ნაგულისხმევი გარანტიები, დამაკმაყოფილებელ ხარისხი ან/და მომხმარებლის მიზნებთან შესაბამისობა)
                კერძოდ, ჩვენ არ ვიძლევით გარანტიას, რომ ვებ-გვერდი ან/და მომსახურება იქნება მუდმივად
                ხელმისაწვდომი ან არ ექნება პროგრამული შეცდომები, ვირუსები ან სხვა შეცდომები.
            </p>
            <p className='terms-standard-text'>21. პერსონალური მონაცემები და პირად ანგარიშზე წარმოებული ოპერაციების შესახებ
                ინფორმაცია კონფიდენციალურია, არ ექვემდებარება გასაჯაროებას გარდა კანონმდებლობით
                გათვალისწინებული შემთხვევებისა;</p>
            <p className='terms-standard-text'>22. წინამდებარე პირობებით განსაზღვრული ვალდებულებების დარღვევა იწვევს წინამდებარე პირობებით
            და საქართველოს მოქმედი კანონმდებლობით გათვალისწინებულ პასუხისმგებლობას; მხარეები იღებენ
            ვალდებულებას აუნაზღაურონ ერთმანეთს მათ მიერ პირობების სრულად ან ნაწილობრივ
            შეუსრულებლობის ან არაჯეროვნად შესრულების შედეგად მიყენებული ზიანი (ზარალი) კანონმდებლობით
            ან/და პირობებით დადგენილი წესით;</p>
            <p className='terms-standard-text'>23. წინამდებარე წესების და პირობების ძალაში შესვლის შემდგომ ძალაში რჩება მომხმარებლის
            რეგისტრაციის გაუქმებამდე.</p>
            <p className='terms-standard-text'>24. წინამდებარე პირობები რეგულირდება და განიმარტება საქართველოს კანონმდებლობის შესაბამისად;</p>
            <p className='terms-standard-text'>25. ამ პირობებთან ან მის ინტერპრეტაციასთან დაკავშირებული ნებისმიერი დავა ან უთანხმოება
            გადაწყდება მხარეებს შორის მოლაპარაკების გზით; თუ მხარეები ვერ აღწევენ შეთანხმებას მოლაპარაკების
            გზით, ისინი უპირობოდ თანახმა არიან სურვილის შემთხვევაში მიმართონ მათთვის საქართველოს
            კანონმდებლობით მინიჭებულ დავის გადაწყვეტის სხვა საშუალებებს.</p>
            <p className='terms-standard-text'>26. წინამდებარე პირობები შესაძლებელია დაექვემდებაროს ცვლილებებს. . USDELIVERY.GE-ს უფლება აქვს
            ცალმხრივად შეიტანოს ცვლილებები ამ პირობების ტექსტში და აღნიშნულის შესახებ აცნობოს
            მომხმარებელს USDELIVERY.GE-ს საინფორმაციო საშუალებების, მათ შორის ვებ-გვერდის,
            USDELIVERY.GE-ს ხელმისაწვდომი ნებისმიერი სხვა არხის ან/და ელექტრონული ფოსტის საშუალებით;
            პირობების ყოველი არსებითი ცვლილებისას მომხმარებელს ექნება შესაძლებლობა განაცხადოს თანხმობა
            USDELIVERY.GE-ს ნებისმიერი არხით სარგებლობისას.</p>
            <p className='terms-standard-text'>27. მომხმარებელი,  USDELIVERY.GE-სთან წერილობითი თანხმობის გარეშე, არ არის უფლებამოსილი ამ
            პირობებიდან გამომდინარე უფლებები და მოთხოვნები გადასცეს ან ვალდებულებები გადააკისროს სხვა
            პირს;</p>

            <p className='terms-standard-text'>28. წინამდებარე წესები და პირობები, მასთან დაკავშირებულ სხვა ხელშეკრულებებთან ერთად წამოადგენს
            ერთიან და მთლიან შეთანხმებას ვებ-გვერდთან ან/და სერვისებთან დაკავშირებით და ანაცვლებს ვებ-
            გვერდთან ან/და სერვისებთან დაკავშირებულ ყველა წინარე და პარალელურ რეჟიმში განხორციელებულ
            კომუნიკაციას, აღქმას, ასევე წერილობით ან ზეპირ განცხადებებსა და გარანტიებს.</p>
            <p className='terms-standard-text'>29. ის შემთხვევები, რომლებიც არ არის გათვალისწინებული ხელშეკრულებით, რეგულირდება
            საქართველოს მოქმედი კანონმდებლობით ან/და დამატებით შეთანხმებული პირობებით;</p>
            <p className='terms-standard-text' style={{ marginBottom: "35px" }}>30. ფორს მაჟორის დროს მხარეები თავისუფლდებიან ხელშეკრულებით ნაკისრი ვალდებულებ(ებ)ისაგან;
            ფორს მაჟორი წარმოადგენს ისეთ გარემოებას, რომელიც წარმოიქმნება და მიმდინარეობს მხარის
            კონტროლს მიღმა და მხარეს, ობიექტური მოცემულობიდან გამომდინარე, არ აქვს შესაძლებლობა
            მოახდინოს რაიმე გავლენა ამ გარემოებაზე. ფორს მაჟორი უნდა იყოს საყოველთაოდ ცნობილი
            მოვლენა/ფაქტი ან მოვლენა/ფაქტი, რომელიც დასტურდება ავტორიტეტული და სანდო წყაროს მეშვეობით.</p>

            <p className='terms-standard-text'>მადლობას გიხდით თანამშრომლობისთვის.</p>
        </div>
    )
}

export default TermsAndConditions