import React, { useEffect } from "react";
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const Danger = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        activePageSetter("danger")
    }, [activePageSetter])

    return(
        <div className={'content'}>
            <div className={'banner-image-container'} >
                <img src={`/static/image/danger.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={'container'}>
                <p className={'restrict-title'} style={{marginTop: "16px"}}>{languages[lang].servicesPages.prohibitedItems.title}</p>
                <p className={'restrict-subtitle'}>{languages[lang].servicesPages.prohibitedItems.usa}</p>
                <p className={'restrict-subtitle'}>{languages[lang].servicesPages.prohibitedItems.subtitle}</p>
                <ul>
                    {languages[lang].servicesPages.prohibitedItems.prohibited.map((p, k) => {
                        return <li key={k * 20} className={'restrict-text'}>{p}</li>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Danger