import React, { useEffect } from "react";
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const HowToBuy = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        activePageSetter("how-to-buy")
    }, [activePageSetter])

    return(
        <div className={'content'}>
            <div className={'banner-image-container'}>
                <img src={`/static/image/howtobuy.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={"container"}>
                <p className={'title'} style={{marginTop: "16px"}}>{languages[lang].servicesPages.usefulInformation.title}</p>
                <ul style={{listStyleType: "none", margin: "0px", padding: "0px"}}>
                    {languages[lang].servicesPages.usefulInformation.content.map((p, k) => {
                        return <li key={k * 14} className={'text'}>{p}</li>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default HowToBuy