import { useEffect, useState } from "react"
import axios from "axios"
import './Admin.css'

const Dashboard = ({ setIsAdmin }) => {

    const [count, setCount] = useState({})

    useEffect(() => {
        axios('/api/admin/data-count')
        .then((res) => {
            const data = res.data.count;
            setCount({ ...data });
        })
        .catch((err) => console.log(err))
    }, [])

    return (
        <div className="admin-dashboard">
            <div className="users-count-box">
                <h3 className="box-title">Users</h3>
                <span className="count-number">{count.users}</span>
            </div>
            <div className="active-parcels-count">
                <h3 className="box-title">Parcels</h3>
                <span className="count-number">{count.parcels}</span>
            </div>
            <div className="active-flights-count">
                <h3 className="box-title">Flights</h3>
                <span className="count-number">{count.flights}</span>
            </div>
        </div>
    )
}

export default Dashboard