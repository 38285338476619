import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import './Sidebar.css'
import languages from '../../data/languages'
import {useSelector} from "react-redux";

const Sidebar = ({ auth, isAdmin }) => {
    const lang = useSelector((state) => state.language.language);

    return (
        <div className='sidebar-container'>
            <ul className='sidebar-content-list'>
                {!isAdmin && (<>
                    <li className='sidebar-item'><Link to="/">{languages[lang].navigation.main}</Link></li>
                    {auth && <li className='sidebar-item'><Link to="/my-page">{languages[lang].navigation.myPage}</Link></li>}
                    <li className='sidebar-item'><Link to="/flights">{languages[lang].navigation.flights}</Link></li>
                    <li className='sidebar-item'><Link to="/shopping">{languages[lang].navigation.shopping}</Link></li>
                    <li className='sidebar-item'><Link to="/services/prices">{languages[lang].navigation.price}</Link></li>
                    <li className='sidebar-item'><Link to="/services/online-parcels">{languages[lang].navigation.services}</Link></li>
                    <li className='sidebar-item'><Link to="/useful-information/how-to-buy">{languages[lang].navigation.info}</Link></li>
                    <li className='sidebar-item'><Link to="/contact">{languages[lang].navigation.contact}</Link></li>
                </>)}
                {
                    isAdmin && (
                        <>
                            <li className='sidebar-item'><Link to="/admin/dashboard">{languages[lang].navigation.dashboard}</Link></li>
                            <li className='sidebar-item'><Link to="/admin/delivery">{languages[lang].navigation.delivery}</Link></li>
                            <li className='sidebar-item'><Link to="/admin/markets">{languages[lang].navigation.market}</Link></li>
                            <li className='sidebar-item'><Link to="/admin/flights">{languages[lang].navigation.flights}</Link></li>
                            <li className='sidebar-item'><Link to="/admin/users">{languages[lang].navigation.users}</Link></li>
                            <li className='sidebar-item'><Link to="/admin/parcels">{languages[lang].navigation.parcels}</Link></li>
                        </>
                    )
                }
            </ul>
        </div>
    )
}

export default Sidebar