import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Modal, Table } from "react-bootstrap";

const Users = () => {
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [pageChanged, setPageChanged] = useState(1);
    const [users, setUsers] = useState([]);
    const [active, setActive] = useState({});
    const [showViewMode, setShowViewMode] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });
    const officesList = ["თბილისი 47 ვაჟა-ფშაველას გამზირი", "რუსთავი-თოდრიას 15", "ქუთაისი- ჭავჭავაძის 39", "ფოთი-ბაგრატიონის 21", "ზუგდიდი-თათარაშვილის 34", "გორი", "ქობულეთი", "ბათუმი"]
    const [formData, setFormData] = useState({
        name: "", surname: "", officeAddress: "", email: "", personalNumber: "", phone: "", bonus: "", balance: "", debt: ""
    });

    const searchByKeyword = (e) => {
        if (e.keyCode === 13) {
            axios(`/api/users?page=${pageChanged}&limit=10&keyword=${keyword}`)
                .then((response) => {
                    setTotal(response.data.count)
                    const data = response.data.data;
                    setUsers([...data])
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const exportUsers = () => {
        axios.post(`/api/export/users`)
            .then((response) => {
                const url = response.data.url;
                window.open(url);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const editUserData = (e) => {
        e.preventDefault();
        axios.put(`/api/user/${active.userId}`, formData)
            .then((res) => {
                setShowEditUserModal(false);
                setNotification({
                    show: true,
                    header: "მომხმარებლის რედაქტირება",
                    body: "მომხმარების მონაცემები რედაქტირებულია",
                    button: "დახურვა"
                });

                const edit = users.map((u) => {
                    if (u.userId === active.userId) {
                        return { ...u, name: formData.name, deliveryDebt: formData.deliveryDebt, surname: formData.surname, officeAddress: formData.officeAddress, personalbar: formData.personalNumber, email: formData.email, phone: formData.phone, bonus: formData.bonus, balance: formData.balance, debt: formData.debt };
                    } else {
                        return { ...u }
                    }
                })

                setUsers([...edit])
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description.En;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    useEffect(() => {
        axios(`/api/users?limit=10&page=${pageChanged}&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setUsers(data);
                setTotal(res.data.count)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageChanged])

    return (
        <>
            <div className="admin-flights-head" style={{ justifyContent: "space-between" }}>
                <div className="search-keyword-container">
                    <input type="text" onKeyDown={searchByKeyword} placeholder="ჩაწერეთ საძიებო სიტყვა" onChange={(e) => setKeyword(e.target.value)} className="form-control" style={{ maxWidth: "400px" }} />
                </div>
                <Button variant="secondary" onClick={exportUsers}>Export</Button>
                <Button variant="primary" onClick={() => {
                    axios.post(`/api/export/debt`)
                        .then((response) => {
                            const url = response.data.url;
                            window.open(url);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }}>Export Debt</Button>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>სახელი</th>
                        <th>გვარი</th>
                        <th>ნომერი</th>
                        <th>ელ.ფოსტა</th>
                        <th>ბალანსი</th>
                        <th>დავალიანება</th>
                        <th>მიწოდების დავალიანება</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => {
                        return <tr key={user.userId}>
                            <td>{user.userId}</td>
                            <td>{user.name}</td>
                            <td>{user.surname}</td>
                            <td>{user.phone}</td>
                            <td>{user.email}</td>
                            <td>{user.balance === 0 ? '0.00' : user.balance}</td>
                            <td>{user.debt === 0 ? '0.00' : user.debt}</td>
                            <td>{user.deliveryDebt}</td>
                            <td>
                                <button title='View user' onClick={() => {
                                    setActive({ ...user });
                                    setShowViewMode(true)
                                }} className="none-button"><img style={{ width: "20px" }} src="/static/image/eye.svg" alt="actions" /></button>
                                <button title='Edit user' onClick={() => {
                                    setActive({ ...user });
                                    setFormData({
                                        name: user.name, deliveryDebt: user.deliveryDebt, surname: user.surname, officeAddress: user.officeAddress, email: user.email, personalNumber: user.personalNumber, phone: user.phone, bonus: user.bonus, balance: user.balance, debt: user.debt
                                    });
                                    setShowEditUserModal(true)
                                }} className="none-button"><img style={{ width: "20px" }} src="/static/image/edit.svg" alt="actions" /></button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>

            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>

            <Modal show={showViewMode} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowViewMode(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>მომხმარებლის მონაცემები</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="container" style={{ paddingTop: "0px" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ოთახის ნომერი:</p>
                                    <p className="view-mode-value">{active.userId}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ნომერი:</p>
                                    <p className="view-mode-value">{active.phone}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">პირადი ნომერი:</p>
                                    <p className="view-mode-value">{active.personalNumber}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ბალანსი:</p>
                                    <p className="view-mode-value">{active.balance === 0 ? "0.00" : active.balance}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ბონუსი:</p>
                                    <p className="view-mode-value">{active.bonus === 0 ? "0.00" : active.bonus}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">მიწოდების დავალიანება:</p>
                                    <p className="view-mode-value">{active.deliveryDebt === 0 ? "0.00" : active.deliveryDebt}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">სახელი:</p>
                                    <p className="view-mode-value">{active.name}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">გვარი:</p>
                                    <p className="view-mode-value">{active.surname}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">ელ.ფოსტა:</p>
                                    <p className="view-mode-value">{active.email}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">მისამართი:</p>
                                    <p className="view-mode-value">{active.officeAddress}</p>
                                </div>
                                <div className="view-users-section-item">
                                    <p className="view-mode-option">დავალიანება:</p>
                                    <p className="view-mode-value">{active.debt === 0 ? "0.00" : active.debt}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowViewMode(false)}>დახურვა</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditUserModal} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowEditUserModal(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>მომხმარებლის მონაცემები</h3>
                </Modal.Header>
                <Modal.Body>
                    <form className="container" style={{ paddingTop: "0px" }} onSubmit={editUserData}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">სახელი</label>
                                    <input type="text" className="form-control" value={formData.name} onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">გვარი</label>
                                    <input type="text" className="form-control" value={formData.surname} onChange={(e) => setFormData((prev) => ({ ...prev, surname: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ოფისის მისამართი</label>
                                    <select type="text" className="form-control" value={formData.officeAddress} onChange={(e) => setFormData((prev) => ({ ...prev, officeAddress: e.target.value }))} required>
                                        <option>
                                            ოფისის მისამართი
                                        </option>
                                        {officesList.map((o, k) => {
                                            return (
                                                <option key={k * 20} value={o}>{o}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">პირადი ნომერი</label>
                                    <input type="text" className="form-control" value={formData.personalNumber} onChange={(e) => setFormData((prev) => ({ ...prev, personalNumber: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ელ. ფოსტა</label>
                                    <input type="email" className="form-control" value={formData.email} onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ნომერი</label>
                                    <input type="text" className="form-control" value={formData.phone} onChange={(e) => setFormData((prev) => ({ ...prev, phone: e.target.value }))} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ბონუსი</label>
                                    <input type="text" className="form-control" value={formData.bonus} onChange={(e) => setFormData((prev) => ({ ...prev, bonus: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">ბალანსი</label>
                                    <input type="text" className="form-control" value={formData.balance} onChange={(e) => setFormData((prev) => ({ ...prev, balance: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">დავალიანება</label>
                                    <input type="text" className="form-control" value={formData.debt} onChange={(e) => setFormData((prev) => ({ ...prev, debt: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">მიწოდების დავალიანება</label>
                                    <input type="text" className="form-control" value={formData.deliveryDebt} onChange={(e) => setFormData((prev) => ({ ...prev, deliveryDebt: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">პაროლი</label>
                                    <input type="text" className="form-control" value={formData.password} onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 submit-form-buttons">
                            <Button variant="primary" style={{ marginRight: "20px" }} onClick={() => setShowEditUserModal(false)}>დახურვა</Button>
                            <Button variant="danger" type="submit">შენახვა</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Users