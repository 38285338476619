import React, { useEffect } from "react";
import './Services.css'
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const Bonus = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        activePageSetter("bonus")
    }, [activePageSetter])

    return(
        <div className={"content"}>
            <div className={'banner-image-container'}>
                <img src={`/static/image/bonus.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={"container"}>
                <p className={'title'} style={{marginTop: "16px"}}>{languages[lang].servicesPages.bonuses.title}</p>
                <p className={'subtitle'}>{languages[lang].servicesPages.bonuses.subtitle}</p>
                <p className={'text'}>{languages[lang].servicesPages.bonuses.description}</p>
            </div>
        </div>
    )
}

export default Bonus