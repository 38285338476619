import React, { useEffect } from "react";
import './Services.css'
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const Delivery = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        activePageSetter("delivery")
    }, [activePageSetter])
    return(
        <div className={"content"}>
            <div className={'banner-image-container'} style={{zIndex: "-1"}} >
                <img src={`/static/image/shipping.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={"container"}>
                <p className={'title'} style={{marginTop: "16px"}}>{languages[lang].servicesPages.delivery.title}</p>
                <p className={'text'}>{languages[lang].servicesPages.delivery.courierService}</p>
                <p className={'title'}>{languages[lang].servicesPages.delivery.title2}</p>
                <p className={'title'}>{languages[lang].servicesPages.delivery.title3}</p>
                <p className={'text'}>{languages[lang].servicesPages.delivery.Description3}</p>
                <p className={'title'}>{languages[lang].servicesPages.delivery.title4}</p>
                <p className={'text'}>{languages[lang].servicesPages.delivery.description4}</p>
                <p className={'title'}>{languages[lang].servicesPages.delivery.title5}</p>
                <p className={'text'}>{languages[lang].servicesPages.delivery.description5}</p>
                <p className={'text'}>{languages[lang].servicesPages.delivery.weightAndPrices}</p>
            </div>
        </div>
    )
}

export default Delivery