import React, {useEffect} from "react";
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const Transportation = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        activePageSetter("transportation")
    }, [activePageSetter])

    return(
        <div className={"content"}>
            <div className={'banner-image-container'}>
                <img src={`/static/image/date.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={"container"}>
                <p className={'restrict-subtitle'}>{languages[lang].servicesPages.deliveryPrices.usa}</p>
                <p className={'restrict-subtitle'}>
                    {languages[lang].servicesPages.deliveryPrices.poland}
                </p>
                <p className={'restrict-subtitle'}>{languages[lang].servicesPages.deliveryPrices.greece}</p>
                <p className={'restrict-subtitle'}>{languages[lang].servicesPages.deliveryPrices.turkey}</p>
            </div>
        </div>
    )
}

export default Transportation