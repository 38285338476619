import { useEffect, useState } from 'react';
import './FlightsSlider.css';
import axios from 'axios';
import languages from '../../../data/languages';
import {useSelector} from "react-redux";

const FlightsSlider = () => {
    const lang = useSelector((state) => state.language.language);
    const [flightsInProcess, setFlightsInProcess] = useState([]);
    const [arrivedParcels, setArrivedParcels] = useState([]);
    useEffect(() => {
        axios(`/api/flights?status=გზაშია&limit=20&page=1`)
            .then((response) => {
                const data = response.data.data;
                setFlightsInProcess([...data]);
            })
            .catch((err) => {
                console.log(err);
            })
        axios(`/api/flights?status=ჩამოსულია&limit=20&page=1`)
            .then((response) => {
                const data = response.data.data;
                setArrivedParcels([...data]);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    return (
        <>
            <div className='flights-slider-container'>
                {
                    flightsInProcess.map((f) => {
                        return (
                            <div key={f.flightId} className='flight-slider-item'>
                                <div className='flights-col'>
                                    <p><span className='first-col'>{languages[lang].mainPage.id}:</span> {f.flightId}</p>
                                    <p><span className='first-col'>{languages[lang].mainPage.status}:</span> {languages[lang].mainPage.on_the_way}</p>
                                    <p><span className='first-col'>{languages[lang].mainPage.country}:</span> {f.country}</p>
                                </div>
                                <div className='flights-col' style={{ marginLeft: "14px" }}>
                                    <p><span className='sec-col'>{languages[lang].mainPage.take_off}:</span> {f.estimatedArrivalDate}</p>
                                    <p><span className='sec-col'>{languages[lang].mainPage.arrive}:</span> {f.takeOffDate}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='flights-slider-container' style={{marginTop: "30px"}}>
                {
                    arrivedParcels.map((f) => {
                        return (
                            <div key={f.flightId} className='flight-slider-item' style={{minWidth: "445px"}}>
                                <div className='flights-col'>
                                    <p><span className='first-col'>{languages[lang].mainPage.id}:</span> {f.flightId}</p>
                                    <p><span className='first-col'>{languages[lang].mainPage.status}:</span>{languages[lang].mainPage.arrived} </p>
                                    <p><span className='first-col'>{languages[lang].mainPage.country}:</span> {f.country}</p>
                                </div>
                                <div className='flights-col' style={{ marginLeft: "14px" }}>
                                    <p><span className='sec-col'>{languages[lang].mainPage.take_off}:</span> {f.takeOffDate} </p>
                                    <p><span className='sec-col'>{languages[lang].mainPage.arrive}:</span> {f.estimatedArrivalDate} </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default FlightsSlider