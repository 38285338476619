import axios from "axios"
import { useEffect, useState } from "react"
import { Modal, Table, Button } from "react-bootstrap";

const Delivery = () => {
    const [delivery, setDelivery] = useState([]);
    const [pageChanged, setPageChanged] = useState(1);
    const [total, setTotal] = useState(0);
    const [editDeliveryModal, setEditDeliverymodal] = useState(false);
    const [price, setPrice] = useState("");
    const [address, setAddress] = useState("");
    const [active, setActive] = useState({});
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });
    const [keyword, setKeyword] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        axios.get(`/api/parcels/delivery?limit=10&page=${pageChanged}&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setDelivery([...data]);
                setTotal(res.data.totalCount);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageChanged, keyword])

    const saveDeliveryPrice = () => {
        axios.put(`/api/parcels/${active.id}/delivery`, { price: price, userId: active.userId, address: address, status: status })
            .then(() => {
                setNotification({
                    show: true,
                    header: "მიროდების სერვისის დავალიანება",
                    body: "დავალიანება დამატებულია",
                    button: "დახურვა"
                })

                const data = delivery.map((d) => {
                    if (d.id === active.id) {
                        return { ...d, price: price, status: status }
                    } else {
                        return { ...d }
                    }
                })

                setDelivery([...data]);
                setEditDeliverymodal(false)
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const removeDelivery = () => {
        axios.delete(`/api/parcels/${active.id}/delivery`)
            .then(() => {
                setEditDeliverymodal(false);
                const d = delivery.filter((dbt) => dbt.id !== active.id);
                setDelivery([...d]);
            })
            .catch(() => {
                alert("Something went wrong")
            })
    }

    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <input type={"text"} onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        setKeyword(e.target.value);
                        setPageChanged(1)
                    }
                }} placeholder={"Search by user ID or date"} className={"form-control"} />
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>მომხმარებელი</th>
                        <th>ელ-ფოსტა</th>
                        <th>მისამართი</th>
                        <th>თარიღი</th>
                        <th>ფასი</th>
                        <th>სტატუსი</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        delivery.map((d, l) => {
                            return (
                                <tr key={l * 25}>
                                    <td>{d.userId}</td>
                                    <td>{d.email}</td>
                                    <td>{d.address}</td>
                                    <td>{d.date}</td>
                                    <td>{parseFloat(d.price).toFixed(2)}</td>
                                    <td>{d.status}</td>
                                    <td>
                                        <img onClick={() => {
                                            setAddress(d.address);
                                            setActive({ ...d });
                                            setEditDeliverymodal(true);
                                            setPrice(d.price);
                                            setStatus(d.status);
                                        }} src="/static/image/edit.svg" alt="actions" style={{ width: "20px", cursor: "pointer" }} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>

            <Modal show={editDeliveryModal} size="მდ" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setEditDeliverymodal(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>მიტანის სერვისი</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="mt-3 mb-1 color-white">მომხმარებელი</label>
                        <input type="text" className="form-control" value={active.userId} disabled />
                    </div>
                    <div className="form-group">
                        <label className="mt-3 mb-1 color-white">ელ. ფოსტა</label>
                        <input type="text" className="form-control" value={active.email} disabled />
                    </div>
                    <div className="form-group">
                        <label className="mt-3 mb-1 color-white">მისამართი</label>
                        <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label className="mt-3 mb-1 color-white">ტრანსპორტირების თანხა</label>
                        <input type="text" className="form-control" value={price} onChange={(e) => setPrice(e.target.value)} />
                    </div>
                    <div className={"form-group"} style={{ margin: "20px 0" }}>
                        <label style={{ color: "white" }}>სტატუსი</label>
                        <select className={"form-control"} value={active.status} onChange={(e) => {
                            setActive((prev) => ({ ...prev, status: e.target.value }));
                            setStatus(e.target.value);
                        }}>
                            <option value={""}>მონიშნე სტატუსი</option>
                            <option value={"send"}>გაგზავნილი</option>
                            <option value={"canceled"}>გაუქმებული</option>
                            <option value={"warehouse"}>საწყობში</option>
                        </select>
                    </div>
                    <h5 style={{ color: "white" }}>გზავნილები</h5>
                    <div className="delivery-trackings">
                        {
                            typeof active.parcels !== "undefined" && (
                                Array.isArray(active.parcels) ? (active.parcels.map((p) => {
                                    return <>
                                        <span className="tracking-item">
                                            {p + ","}
                                        </span>
                                    </>
                                })) : JSON.parse(active.parcels).map((p) => {
                                    return <>
                                        <span className="tracking-item">
                                            {p + ","}
                                        </span>
                                    </>
                                })
                            )
                        }
                    </div>
                    <h5 style={{ color: "white" }}>კომენტარი</h5>
                    <p style={{ color: "white" }}>{active.comment}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={removeDelivery}>წაშლა</Button>
                    <Button variant="info" onClick={() => setEditDeliverymodal(false)}>დახურვა</Button>
                    <Button variant="success" onClick={() => saveDeliveryPrice()}>შენახვა</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Delivery