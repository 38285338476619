import React, { useEffect, useState } from "react";
import "./App.css";
import "./reset.css"
import { Switch } from "react-router-dom";
import Header from "./Common/Header/Header";
import Sidebar from "./Common/Sidebar/Sidebar";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import Main from "./pages/Main";
import Footer from "./Common/Footer/Footer";
import Flights from "./pages/Flights";
import MyPage from "./pages/MyPage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "./store/user";
import Services from "./Components/Services/Services";
import UsefullInformation from "./Components/UsefullInformation/UsefullInformation";
import AdminRoute from "./Admin/AdminRoute";
import TermsAndConditions from "./Components/TermsAndConditions";
import { Modal } from "react-bootstrap";
import Markets from "./pages/Markets";

function App() {
    const dispatch = useDispatch();
    const [admin, setAdmin] = useState(false);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const role = useSelector((state) => state.user.role);
    const [showAppSuggest, setShowAppSuggest] = useState(true);
    const screen = window.innerWidth < 800;
    useEffect(() => {
        axios(`/api/user`, {
            withCredentials: true,
        })
            .then((res) => {
                const data = res.data.data;
                dispatch(userAction.setIsLoggedIn(true));
                dispatch(userAction.setUserData({
                    name: data.name,
                    surname: data.surname,
                    initials: `${data.name.slice(0, 1)}${data.surname.slice(0, 1)}`,
                    userId: data.userId,
                    role: data.role,
                    phone: data.phone,
                    address: data.address,
                    email: data.email,
                    officeAddress: data.officeAddress,
                    country: data.country
                }))
            })
            .catch((err) => {
                console.log(err);
                dispatch(userAction.setIsLoggedIn(false));
            })
    }, [dispatch])

    return (
        <React.Fragment>
            <Header />
            <div className="pages-container">
                <div className="sidebar-box">
                    <Sidebar auth={isLoggedIn} isAdmin={admin} />
                </div>
                <div className="pager">
                    <Switch>
                        <Route path='/' exact><Main setIsAdmin={setAdmin} /></Route>
                        <Route path='/flights' exact><Flights setIsAdmin={setAdmin} /></Route>
                        {isLoggedIn !== false && <Route path='/my-page' exact><MyPage setIsAdmin={setAdmin} /></Route>}
                        <Route path='/services'><Services setIsAdmin={setAdmin} /></Route>
                        <Route path='/shopping'><Markets setIsAdmin={setAdmin} /></Route>
                        <Route path='/useful-information'><UsefullInformation  setIsAdmin={setAdmin}/></Route>
                        <Route path='/terms-and-conditions'><TermsAndConditions /></Route>
                        {(role === "admin" || role === "sadmin") && <Route path='/admin'>
                            <AdminRoute setIsAdmin={setAdmin} />
                        </Route>}
                        <Route path='/*'><div className="page-404">
                            <h2>404 Page not found</h2>
                        </div></Route>
                    </Switch>
                </div>
            </div>
            {
                screen && <Modal show={showAppSuggest} onHide={() => setShowAppSuggest(false)} centered>
                    <Modal.Body>
                        <h2 style={{color: "white", fontSize: "24px"}}>გადმოწერეთ ჩვენი მობაილ აპლიკაცია</h2>
                        <a target="_blank" style={{width: "100%", marginTop: "30px", borderRadius: "10px", padding: "12px 0", alignItems: "center", textDecoration: "none", background: "#1a2b3c", display: "flex", justifyContent: "center"}} href="https://usdelivery.ge/static/files/usdelivery.apk">
                            <img style={{width: "70px"}} src="/static/image/logo.png"/>
                            <p style={{color: "white", marginBottom: "0px", fontSize: "21px", marginLeft: "15px"}}>გადმოწერა</p>
                        </a>
                    </Modal.Body>
                </Modal>
            }
            <Footer />
        </React.Fragment>
    );
}

export default App;
