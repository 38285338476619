import axios from "axios"
import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import languages from "../data/languages"
import { useSelector } from "react-redux"

const DeliveryList = () => {
    const [pageChanged, setPageChanged] = useState(1)
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const lang = useSelector((state) => state.language.language);
    const [delivery, setDelivery] = useState([]);

    useEffect(() => {
        axios.get(`/api/users/parcels/delivery?page=${pageChanged}&limit=${limit}`)
            .then((res) => {
                const data = res.data.data;
                setDelivery([...data]);
                setTotal(res.data.totalCount);
            })
            .catch((err) => {

            })
    }, [])

    return (
        <>
            <p className="section-titles" style={{ marginTop: "40px" }}>{languages[lang].delivery.title}</p>
            <Table responsive>
                <thead>
                    <tr>
                        <th>{languages[lang].delivery.parcels}</th>
                        <th>{languages[lang].delivery.address}</th>
                        <th>{languages[lang].delivery.price}</th>
                        <th>{languages[lang].delivery.date}</th>
                        <th>{languages[lang].delivery.status}</th>
                    </tr>
                </thead>
                <tbody>
                    {delivery.map((item) => (
                        <tr key={item.id}>
                            <td title={JSON.parse(item.parcels).join(", ")}>{JSON.parse(item.parcels).join(", ").slice(0, 30)}</td>
                            <td>{item.address}</td>
                            <td>{item.price == null ? "" : item.price.toFixed(2)} ლ</td>
                            <td>{item.date}</td>
                            <td>{item.status}</td>
                        </tr>
                    ))}
                    {
                        delivery.length === 0 && (
                            <tr className="blank_row">
                                <td colSpan="10" style={{ textAlign: "center" }}>No items found</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>
        </>
    )
}

export default DeliveryList