import axios from "axios";
import { useState } from "react"
import { Button, Modal } from "react-bootstrap";
import languages from "../../data/languages";
import { useSelector } from "react-redux";
import TermsAndConditions from "../../Components/TermsAndConditions";


const NaturalPerson = ({ showAuthModal, showSignUpModal }) => {
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });
    const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
    const lang = useSelector((state) => state.language.language);
    const [errorModal, setShowErrorModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [formData, setFormData] = useState({
        nameEn: "",
        surnameEn: "",
        nameGeo: "",
        surnameGeo: "",
        personalNumber: "",
        dateOfBirth: "",
        phone: "",
        office: "",
        address: "",
        email: "",
        password: "",
        repeatPassword: "",
        agree: false
    });
    const officesList = ["თბილისი 47 ვაჟა-ფშაველას გამზირი", "რუსთავი-თოდრიას 15", "ქუთაისი- ჭავჭავაძის 39", "ფოთი-ბაგრატიონის 21", "ზუგდიდი-თათარაშვილის 34", "გორი", "ქობულეთი", "ბათუმი"];
    const [error, setError] = useState("")

    const signUpHandler = (e) => {
        e.preventDefault();
        if (!formData.agree) {
            setShowErrorModal(true);
            setError("Please agree our terms and conditions")
        } else {
            axios.post(`/api/user`, formData)
                .then((response) => {
                    const result = response.data.message.Geo;
                    setShowErrorModal(false)
                    setSuccess(true);
                    setSuccessText(result);
                    setFormData({
                        nameEn: "",
                        surnameEn: "",
                        nameGeo: "",
                        surnameGeo: "",
                        personalNumber: "",
                        dateOfBirth: "",
                        phone: "",
                        office: "",
                        address: "",
                        email: "",
                        password: "",
                        repeatPassword: "",
                        agree: false
                    })
                })
                .catch((err) => {
                    const result = err.response.data.message.En;
                    setError(result);
                    setShowErrorModal(true);
                    setSuccess(false);
                })
        }
    }

    return (
        <>
            <form className="container" onSubmit={signUpHandler}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.name}</label>
                            <input type="text" className="form-control" value={formData.nameEn} onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, nameEn: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.name} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.surname}</label>
                            <input type="text" className="form-control" value={formData.surnameEn} onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, surnameEn: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.surname} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.nameGeo}</label>
                            <input type="text" className="form-control" value={formData.nameGeo} onChange={(e) => {
                                if (/^[\u10A0-\u10FF\s]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, nameGeo: e.target.value }))
                                }
                            }
                            } placeholder={languages[lang].registration.nameGeo} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.surnameGeo}</label>
                            <input type="text" className="form-control" value={formData.surnameGeo} onChange={(e) => {
                                if (/^[\u10A0-\u10FF\s]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, surnameGeo: e.target.value }))
                                }
                            }} placeholder={languages[lang].registration.surnameGeo} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.personal}</label>
                            <input type="text" className="form-control" value={formData.personalNumber} onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, personalNumber: e.target.value }));
                                }
                            }} placeholder={languages[lang].registration.personal} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.dateOfBirth}</label>
                            <input type="date" className="form-control" value={formData.dateOfBirth} onChange={(e) => setFormData((prev) => ({ ...prev, dateOfBirth: e.target.value }))} required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.phone}</label>
                            <input type="text" className="form-control" value={formData.phone} onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value) || e.target.value === '') {
                                    setFormData((prev) => ({ ...prev, phone: e.target.value }))
                                }
                            }
                            } placeholder={languages[lang].registration.phone} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.officeAddress}</label>
                            <select className="form-control" value={formData.office} onChange={(e) => {
                                setFormData((prev) => ({ ...prev, office: e.target.value }));
                                if (e.target.value !== 'თბილისი 47 ვაჟა-ფშაველას გამზირი') {
                                    setNotification({
                                        show: true,
                                        header: "შეტყობინება",
                                        body: "რეგიონებში იგზავნება კვირაში ერთხელ ოთხშაბათობით, საფასური ერთ რეისზე გაგზავნილ ამანათებზე ჯამში 4 ლარი",
                                        button: "დახურვა"
                                    })
                                }
                            }} required>
                                <option>Select office</option>
                                {officesList.map((o, k) => {
                                    return (
                                        <option key={k * 20} value={o}>{o}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.address}</label>
                            <input type="text" className="form-control" value={formData.address} onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))} placeholder={languages[lang].registration.address} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.email}</label>
                            <input type="text" className="form-control" value={formData.email} onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))} placeholder={languages[lang].registration.email} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.password}</label>
                            <input type="password" className="form-control" value={formData.password} onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))} placeholder={languages[lang].registration.password} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">{languages[lang].registration.repeatPass}</label>
                            <input type="password" className="form-control" value={formData.repeatPassword} onChange={(e) => setFormData((prev) => ({ ...prev, repeatPassword: e.target.value }))} placeholder={languages[lang].registration.repeatPass} required />
                        </div>
                    </div>
                    <div className="terms-and-conditions-agreement">
                        <input style={{ marginTop: "0px" }} id="agree" onChange={(e) => setFormData((prev) => ({ ...prev, agree: e.target.checked }))} value={formData.agree} className="form-check-input" type="checkbox" />
                        <label htmlFor="agree">{languages[lang].registration.terms[0]}</label>
                        <span onClick={() => setShowTermsAndConditionsModal(true)} style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}>{languages[lang].registration.terms[1]}</span>
                    </div>
                    <div className="mt-3">
                        {errorModal && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{successText}</div>}
                    </div>
                    <div className="col-md-12">
                        <button className="sign-up-button" type="submit">{languages[lang].registration.title}</button>
                    </div>
                </div>
                <div className="redirect-to-log-in">
                    <span>{languages[lang].registration.alreadyRegistered[0]}</span>
                    <button onClick={() => {
                        showAuthModal(true);
                        showSignUpModal(false);
                    }}>{languages[lang].registration.alreadyRegistered[1]}</button>
                </div>
            </form>

            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showTermsAndConditionsModal} onHide={() => setShowTermsAndConditionsModal(false)} size="xl" centered >
                <div className="terms-and-conditions-box">
                    <TermsAndConditions />
                </div>
            </Modal>
        </>
    )
}

export default NaturalPerson