import './Banner.css'

const Banner = () => {
    return(
        <div className='banner-container'>
            <img src={`/static/image/slide.jpg`} alt='banner icon'/>
        </div>
    )
}

export default Banner