import { createSlice } from "@reduxjs/toolkit";

const initialSiteLanguage = {
    language: "Geo",
}

const language = createSlice({
    name: 'language',
    initialState: initialSiteLanguage,
    reducers: {
        changeLanguage(state){
            state.language = state.language === "Geo" ? "En" : "Geo";
        }
    }
});

export const languagesAction = language.actions;

export default language.reducer;