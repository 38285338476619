import { Modal, Button } from "react-bootstrap";
import languages from "../data/languages";
import { useSelector } from "react-redux";

const Declaration = ({ show, hide, data, dataSetter, saveHandler }) => {
    const lang = useSelector((state) => state.language.language);

    return (
        <Modal show={show} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => hide(false)}>
            <Modal.Header>
                <h3 style={{ color: "white" }}>დეკლარირება</h3>
            </Modal.Header>
            <Modal.Body>
                <form className="container" style={{ paddingTop: "0px" }} onSubmit={saveHandler}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">თრექინგი</label>
                                <input type="text" className="form-control" disabled value={typeof data !== "undefined" && data.tracking} onChange={(e) => dataSetter((prev) => ({ ...prev, tracking: e.target.value }))} required />
                            </div>
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">ფასი</label>
                                <input type="text" className="form-control" value={typeof data !== "undefined" && data.price} onChange={(e) => dataSetter((prev) => ({ ...prev, price: e.target.value }))} required />
                            </div>
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">ვალუტა</label>
                                <select type="text" className="form-control" value={typeof data !== "undefined" && data.currency} onChange={(e) => dataSetter((prev) => ({ ...prev, currency: e.target.value }))} required >
                                    <option value="">{languages[lang].addParcels.currency}</option>
                                    <option value="USD"> USD</option>
                                    <option value="EURO"> Euro</option>
                                    <option value="Zloty"> Zloty</option>
                                    <option value="Lira"> Lira</option>
                                    <option value="Yen"> Yen</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">ქვეყანა</label>
                                <select type="text" className="form-control" disabled value={typeof data !== "undefined" && data.country} onChange={(e) => dataSetter((prev) => ({ ...prev, country: e.target.value }))} required >
                                    <option value="">{languages[lang].addresses.country}</option>
                                    <option value="usa">{languages[lang].cities.usa}</option>
                                    <option value="poland"> {languages[lang].cities.poland}</option>
                                    <option value="greece">{languages[lang].cities.greece}</option>
                                    <option value="turkey">{languages[lang].cities.turkey}</option>
                                    <option value="china">{languages[lang].cities.china}</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">საიტი</label>
                                <input type="text" className="form-control" value={typeof data !== "undefined" && data.website} onChange={(e) => dataSetter((prev) => ({ ...prev, website: e.target.value }))} required />
                            </div>
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">წონა</label>
                                <input disabled type="text" className="form-control" value={typeof data !== "undefined" && data.weight} onChange={(e) => dataSetter((prev) => ({ ...prev, weight: e.target.value }))} required />
                            </div>
                            <div className="form-group">
                                <label className="mt-3 mb-1 color-white">აღწერა</label>
                                <textarea required type="text" rows={"5"} className="form-control textarea" value={typeof data !== "undefined" && data.description} onChange={(e) => dataSetter((prev) => ({ ...prev, description: e.target.value }))} placeholder="" />
                            </div>
                        </div>
                    </div>
                    <div className="upload-files-block">
                        <input type="file" onChange={(e) => dataSetter((prev) => ({ ...prev, file: e.target.files[0] }))} className="form-control" style={{maxWidth: "400px"}}/>
                    </div>
                    <div className="col-md-12 submit-form-buttons">
                        <Button variant="danger" type="submit" style={{ marginRight: "20px" }}>Save</Button>
                        <Button variant="primary" onClick={() => hide(false)}>Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default Declaration