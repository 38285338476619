import {useEffect, useState} from "react";
import axios from "axios";

const Markets = () => {
    const [markets, setMarkets] = useState({});

    useEffect(() => {
        axios('/api/shops')
            .then((res) => {
                const data = res.data.data;
                setMarkets({...data})
            })
            .catch((err) => {

            })
    }, []);

    return(
        <div>
            <div className={'countries-container'}>
                <h4>ამერიკა</h4>
                <div className={"markets-list"}>
                    {
                        markets.usa && markets.usa.map((m) => {
                            return (
                                <a href={m.url} key={m.id} target={"_blank"} rel="noreferrer"
                                   className={"market-item"}>
                                    <div className={"market-image"}>
                                        <img
                                            src={m.icon}
                                            alt={"shop image"}
                                        />
                                    </div>
                                    <p>{m.name}</p>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
            <div className={'countries-container'}>
                <h4>საბერძნეთი</h4>
                <div className={"markets-list"}>
                    {
                        markets.greece && markets.greece.map((m) => {
                            return (
                                <a href={m.url} key={m.id} target={"_blank"} rel="noreferrer"
                                   className={"market-item"}>
                                    <div className={"market-image"}>
                                        <img
                                            src={m.icon}
                                            alt={"shop image"}
                                        />
                                    </div>
                                    <p>{m.name}</p>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
            <div className={'countries-container'}>
                <h4>ჩინეთი</h4>
                <div className={"markets-list"}>
                    {
                        markets.china && markets.china.map((m) => {
                            return (
                                <a href={m.url} key={m.id} target={"_blank"} rel="noreferrer"
                                   className={"market-item"}>
                                    <div className={"market-image"}>
                                        <img
                                            src={m.icon}
                                            alt={"shop image"}
                                        />
                                    </div>
                                    <p>{m.name}</p>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
            <div className={'countries-container'}>
                <h4>თურქეთი</h4>
                <div className={"markets-list"}>
                    {
                        markets.turkey && markets.turkey.map((m) => {
                            return (
                                <a href={m.url} key={m.id} target={"_blank"} rel="noreferrer"
                                   className={"market-item"}>
                                    <div className={"market-image"}>
                                        <img
                                            src={m.icon}
                                            alt={"shop image"}
                                        />
                                    </div>
                                    <p>{m.name}</p>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Markets