import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap";

const UserDropdown = ({ setShowEditProfile, setUserOutside, setShowDropdown }) => {
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });
    const [user, setUser] = useState({});
    useEffect(() => {
        axios('/api/user')
            .then((response) => {
                const data = response.data.data;
                setUser({ ...data });
                setUserOutside(() => ({officeAddress: data.officeAddress, personalNumber: data.personalNumber, email: data.email, phone: data.phone}));
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <>
            <div className="user-dropdown-content">
                <p><span style={{ width: "70px" }}>User ID:</span> <strong> {user.userId}</strong></p>
                <p><span style={{ width: "70px" }}>Balance:</span> <strong> {user.balance === 0 ? "0.00" : user.balance}</strong></p>
                <p><span style={{ width: "70px" }}>Bonus:</span> <strong> {user.bonus === 0 ? "0.00" : user.bonus}</strong></p>
                <p><span style={{ width: "70px" }}>Debt:</span> <strong> {user.debt === 0 ? "0.00" : user.debt}</strong></p>
                <p><span style={{ width: "70px" }}>Delivery debt:</span> <strong> {parseFloat(user.deliveryDebt).toFixed(2)}</strong></p>
                <hr style={{ margin: "10px 0px" }} />
                <div className="header-dropdown-buttons">
                    <button className="edit-profile-button" onClick={() => {
                        setShowEditProfile(true);
                        setShowDropdown(false);
                    }}>რედაქტირება</button>
                    <button className="log-out-button" onClick={() => {
                        axios.post('/api/users/logout')
                            .then(() => {
                                window.location.href = '/';
                            })
                            .catch(() => {
                                alert("Something went wrong")
                            })
                    }}>Log Out</button>
                </div>
            </div>

            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserDropdown