import React, { useEffect } from "react";
import './Services.css'
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const OnlineParcels = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        activePageSetter("online-parcels")
    }, [activePageSetter])
    return(
        <div className="content">
            <div className='banner-image-container' >
                <img src={`/static/image/parcel-icon.svg`} alt="bonus points " className='banner-image'/>
            </div>
            <div className='container'>
                <p className='title' style={{marginTop: "16px"}}>{languages[lang].servicesPages.onlineParcels.title}</p>
                <p className='subtitle'>{languages[lang].servicesPages.onlineParcels.subTitle}</p>
                <p className='text'>{languages[lang].servicesPages.onlineParcels.description}</p>
            </div>
        </div>
    )
}

export default OnlineParcels