import React from "react";
import styles from './Addresses.module.css'

const Addresses = ({addressSelector, active}) => {

    return(
        <>
            <div className={styles['addresses-container']}>
                <div className={`${styles['address-box']} ${active === "usa" && styles['selected']}`} onClick={() => addressSelector("usa")} title="USA" style={{backgroundImage: `url('https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/1200px-Flag_of_the_United_States.svg.png')`}}><p className={styles['country-name']}>{"USA"}</p></div>
                <div className={`${styles['address-box']} ${active === "poland" && styles['selected']}`} onClick={() => addressSelector("poland")} title="POLAND" style={{backgroundImage: `url('https://aceproject.org/archived-content/root/PL_flag.gif/image_preview')`}}><p className={styles['country-name']}>{"Poland"}</p></div>
                <div className={`${styles['address-box']} ${active === "greece" && styles['selected']}`} onClick={() => addressSelector("greece")} title="GREECE" style={{backgroundImage: `url('https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/600px-Flag_of_Greece.svg.png?20160309091801')`}}><p className={styles['country-name']}>{"Greece"}</p></div>
                <div className={`${styles['address-box']} ${active === "turkey" && styles['selected']}`} onClick={() => addressSelector("turkey")} title="TURKEY" style={{backgroundImage: `url('https://cdn.britannica.com/82/4782-004-4119489D/Flag-Turkey.jpg')`}}><p className={styles['country-name']}>{"Turkey"}</p></div>
                <div className={`${styles['address-box']} ${active === "china" && styles['selected']}`}
                     onClick={() => addressSelector("china")} title="TURKEY"
                     style={{backgroundImage: `url('https://cdn.britannica.com/90/7490-004-BAD4AA72/Flag-China.jpg')`}}>
                    <p className={styles['country-name']}>{"China"}</p></div>
            </div>
        </>
    )
}

export default Addresses