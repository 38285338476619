import React, {useEffect, useState} from "react";
import styles from './SelectedAddress.module.css';
import axios from "axios";
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const SelectedAddress = ({location, country}) => {
    const [userData, setUserData] = useState({});
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        axios("/api/user")
            .then((response) => {
                setUserData({...response.data.data})
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div className={styles['data-table']} style={{marginBottom: "25px"}}>
            <ul className={styles['items-list']}>
                <li>
                    <div className={styles['left-side']}>
                        <span
                            className={styles['title']}>{country === "turkey" ? languages[lang].addresses.nameTur : languages[lang].addresses.name}: </span>
                        <span className={styles['value']}>{userData.name}</span>
                    </div>
                    <span onClick={() => {
                        navigator.clipboard.writeText(userData.name)
                    }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                </li>
                <li>
                    <div className={styles['left-side']}>
                        <span
                            className={styles['title']}>{country === "turkey" ? languages[lang].addresses.surnameTur : languages[lang].addresses.surname}: </span>
                        <span className={styles['value']}>{userData.surname}</span>
                    </div>
                    <span onClick={() => {
                        navigator.clipboard.writeText(userData.surname)
                    }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                </li>
                {(country === "turkey" || country === "usa") && <li>
                    <div className={styles['left-side']}>
                        <span className={styles['title']}>Phone: </span>
                        <span className={styles['value']}>{location.phone}</span>
                    </div>
                    <span onClick={() => {
                        navigator.clipboard.writeText(location.phone)
                    }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                </li>}
                {country === "turkey" && (
                    <>
                        <li>
                            <div className={styles['left-side']}>
                                <span className={styles['title']}>{languages[lang].addresses.cityTur}: </span>
                                <span className={styles['value']}>{location.city}</span>
                            </div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(location.phone)
                            }} className={styles['copy']}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                     viewBox="0 0 448 512"><path
                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                            </span>
                        </li>
                        <li>
                            <div className={styles['left-side']}>
                                <span className={styles['title']}>{languages[lang].addresses.district}: </span>
                                <span className={styles['value']}>{location.district}</span>
                            </div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(location.district)
                            }} className={styles['copy']}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                     viewBox="0 0 448 512"><path
                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                            </span>
                        </li>
                        <li>
                            <div className={styles['left-side']}>
                                <span className={styles['title']}>{languages[lang].addresses.streetTur}: </span>
                                <span className={styles['value']}>{location.street}</span>
                            </div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(location.street)
                            }} className={styles['copy']}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                     viewBox="0 0 448 512"><path
                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                            </span>
                        </li>
                        <li>
                            <div className={styles['left-side']}>
                                <span className={styles['title']}>{languages[lang].addresses.addressTitle}: </span>
                                <span className={styles['value']}>{location.address} {userData.userId}</span>
                            </div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(location.address + " " + userData.userId)
                            }} className={styles['copy']}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                     viewBox="0 0 448 512"><path
                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                            </span>
                        </li>
                        <li>
                            <div className={styles['left-side']}>
                                <span className={styles['title']}>{languages[lang].addresses.addressTitle2}: </span>
                                <span className={styles['value']}>{location.addressTitle}</span>
                            </div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(location.addressTitle)
                            }} className={styles['copy']}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                     viewBox="0 0 448 512"><path
                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                            </span>
                        </li>
                        <li>
                            <div className={styles['left-side']}>
                                <span className={styles['title']}>{languages[lang].addresses.postCode}: </span>
                                <span className={styles['value']}>{location.postCode}</span>
                            </div>
                            <span onClick={() => {
                                navigator.clipboard.writeText(location.postCode)
                            }} className={styles['copy']}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                     viewBox="0 0 448 512"><path
                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                            </span>
                        </li>
                    </>
                )}
                {country === "usa" && <>
                    <li>
                        <div className={styles['left-side']}>
                            <span className={styles['title']}>Address1: </span>
                            <span className={styles['value']}>{location.address}</span>
                        </div>
                        <span onClick={() => {
                            navigator.clipboard.writeText(location.address)
                        }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                    </li>
                    <li>
                        <div className={styles['left-side']}>
                            <span className={styles['title']}>Address2: </span>
                            <span
                                className={styles['value']}>{location.country === "USA" && "Unit5, " + userData.userId}</span>
                        </div>
                        <span onClick={() => {
                            navigator.clipboard.writeText("Unit5 " + userData.userId)
                        }} className={styles['copy']}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                    </li>
                    <li>
                        <div className={styles['left-side']}>
                            <span className={styles['title']}>State: </span>
                            <span className={styles['value']}>{location.state}</span>
                        </div>
                        <span onClick={() => {
                            navigator.clipboard.writeText(location.state)
                        }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                    </li>
                    <li>
                        <div className={styles['left-side']}>
                            <span className={styles['title']}>City: </span>
                            <span className={styles['value']}>{location.city}</span>
                        </div>
                        <span onClick={() => {
                            navigator.clipboard.writeText(location.city)
                        }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                    </li>
                    <li>
                        <div className={styles['left-side']}>
                            <span className={styles['title']}>Postal code: </span>
                            <span className={styles['value']}>{location.postCode}</span>
                        </div>
                        <span onClick={() => {
                            navigator.clipboard.writeText(location.postCode)
                        }} className={styles['copy']}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px" viewBox="0 0 448 512"><path
                            d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                    </span>
                    </li>
                </>}
                {
                    country === "poland" && (
                        <>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Telephone: </span>
                                    <span className={styles['value']}>{location.phone}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.phone)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Address 1: </span>
                                    <span className={styles['value']}>{location.address} {userData.userId}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.address + " " + userData.userId)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Town: </span>
                                    <span className={styles['value']}>{location.city}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.city)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Centrum: </span>
                                    <span className={styles['value']}>{location.state}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.state)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Country: </span>
                                    <span className={styles['value']}>{location.country}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.country)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Post code: </span>
                                    <span className={styles['value']}>{location.postCode}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.postCode)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                        </>
                    )
                }
                {
                    country === "greece" && (
                        <>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Country: </span>
                                    <span className={styles['value']}>{location.country}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.country)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Address 1: </span>
                                    <span className={styles['value']}>{location.address + " " + userData.userId}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.address + " " + userData.userId)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>{languages[lang].addresses.flatNumber}: </span>
                                    <span className={styles['value']}>Usdelivery</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText("Usdelivery")
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Province: </span>
                                    <span className={styles['value']}>{location.state}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.state)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Town: </span>
                                    <span className={styles['value']}>{location.city}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.city)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Post code: </span>
                                    <span className={styles['value']}>{location.postCode}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.postCode)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Telephone: </span>
                                    <span className={styles['value']}>{location.phone}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.phone)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                        </>
                    )
                }
                {
                    country === "china" && (
                        <>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Address: </span>
                                    <span className={styles['value']}>{`${location.address} ${location.mark} ${userData.userId}`}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(`${location.address} ${location.mark} ${userData.userId}`)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>MARK: </span>
                                    <span className={styles['value']}>{`${location.mark} ${userData.userId}`}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.mark)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>District: </span>
                                    <span className={styles['value']}>{location.district}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.district)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>City: </span>
                                    <span className={styles['value']}>{location.city}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.city)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Province: </span>
                                    <span className={styles['value']}>{location.province}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.province)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Zip/Postal code: </span>
                                    <span className={styles['value']}>{location.postCode}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.postCode)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                            <li>
                                <div className={styles['left-side']}>
                                    <span className={styles['title']}>Phone number: </span>
                                    <span className={styles['value']}>{location.phone}</span>
                                </div>
                                <span onClick={() => {
                                    navigator.clipboard.writeText(location.phone)
                                }} className={styles['copy']}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="20px" height="20px"
                                         viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                            </li>
                        </>
                    )
                }
                {/*<li>*/}
                {/*    <div className={styles['left-side']}>*/}
                {/*        <span className={styles['title']}>{languages[lang].addresses.city}: </span>*/}
                {/*        <span className={styles['value']}>{location.city}</span>*/}
                {/*    </div>*/}
                {/*    <span onClick={() => {navigator.clipboard.writeText(location.city)}} className={styles['copy']}>*/}
                {/*        Copy*/}
                {/*    </span>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <div className={styles['left-side']}>*/}
                {/*        <span className={styles['title']}>{languages[lang].addresses.state}: </span>*/}
                {/*        <span className={styles['value']}>{location.state}</span>*/}
                {/*    </div>*/}
                {/*    <span onClick={() => {navigator.clipboard.writeText(location.state)}} className={styles['copy']}>*/}
                {/*        Copy*/}
                {/*    </span>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <div className={styles['left-side']}>*/}
                {/*        <span className={styles['title']}>{languages[lang].addresses.country}: </span>*/}
                {/*        <span className={styles['value']}>{location.country}</span>*/}
                {/*    </div>*/}
                {/*    <span onClick={() => {navigator.clipboard.writeText(location.country)}} className={styles['copy']}>*/}
                {/*        Copy*/}
                {/*    </span>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <div className={styles['left-side']}>*/}
                {/*        <span className={styles['title']}>{languages[lang].addresses.postCode}: </span>*/}
                {/*        <span className={styles['value']}>{location.postCode}</span>*/}
                {/*    </div>*/}
                {/*    <span onClick={() => {navigator.clipboard.writeText(location.postCode)}} className={styles['copy']}>*/}
                {/*        Copy*/}
                {/*    </span>*/}
                {/*</li>*/}
                {/*{country !== "turkey" && <li>*/}
                {/*    <div className={styles['left-side']}>*/}
                {/*        <span className={styles['title']}>{languages[lang].addresses.phone}: </span>*/}
                {/*        <span className={styles['value']}>{location.phone}</span>*/}
                {/*    </div>*/}
                {/*    <span onClick={() => {*/}
                {/*        navigator.clipboard.writeText(location.phone)*/}
                {/*    }} className={styles['copy']}>*/}
                {/*        Copy*/}
                {/*    </span>*/}
                {/*</li>}*/}
            </ul>
        </div>
    )
}

export default SelectedAddress
