import { useEffect, useState } from "react"
import HowToBuy from "./HowToBuy";
import { Link, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import Danger from "./Danger";
import Terms from "./Terms";
import Transportation from "./Transportation";
import languages from '../../data/languages';
import {useSelector} from "react-redux";
import './UsefullInformation.css';

const UsefullInformation = ({setIsAdmin}) => {
    const [activeTab, setActiveTab] = useState("");
    const lang = useSelector((state) => state.language.language);
    useEffect(() => {
        setIsAdmin(false)
    }, [setIsAdmin])
    return (
        <>
            <div className="services-items-slider sign-up-role-switcher">
                <Link to="/useful-information/how-to-buy" className='how-to-buy-link'><button className={activeTab === "how-to-buy" ? "active-tab" : ""}>{languages[lang].info.howToFollow}</button></Link>
                <Link to="/useful-information/terms-and-conditions"><button className={activeTab === "terms" ? "active-tab" : ""}>{languages[lang].info.terms}</button></Link>
                <Link to="/useful-information/danger-items"><button className={activeTab === "danger" ? "active-tab" : ""}>{languages[lang].info.restrictedItems}</button></Link>
                <Link to="/useful-information/transportation-dates"><button className={activeTab === "transportation" ? "active-tab" : ""}>{languages[lang].info.transportation}</button></Link>
            </div>
            <Switch>
                <Route path="/useful-information/how-to-buy"><HowToBuy activePageSetter={setActiveTab} /></Route>
                <Route path="/useful-information/terms-and-conditions"><Terms activePageSetter={setActiveTab} /></Route>
                <Route path="/useful-information/danger-items"><Danger activePageSetter={setActiveTab} /></Route>
                <Route path="/useful-information/transportation-dates"><Transportation activePageSetter={setActiveTab} /></Route>
            </Switch>
        </>
    )
}

export default UsefullInformation