import axios from "axios"
import { useEffect, useState } from "react"
import { Table, Button, Modal } from 'react-bootstrap'
import Addresses from "../Components/Addresses/Addresses";
import SelectedAddress from "../Components/Addresses/SelectedAddress";
import { useSelector } from "react-redux";
import languages from "../data/languages";
import Declaration from "./Declaration";
import DeliveryList from "../Components/DeliveryList";

const addresses = {
    usa: {
        address: "22 Parkway Cir",
        city: "New Castle",
        state: "DELAWARE (DE)",
        country: "USA",
        postCode: "19720",
        phone: "646 659-8429",
    },
    poland: {
        address: "Ul. Jasna 3/2",
        city: "Jelenia Gora",
        state: "Nerse sp.z.o.o.",
        country: "Poland",
        postCode: "58-500",
        phone: "534960700",
    },
    greece: {
        address: "Deligiorgi 3, Athina",
        city: "Athina",
        state: "ATTIKI",
        country: "Greece",
        postCode: "10437",
        phone: "6996814498",
    },
    turkey: {
        address: "Gama-Zafer iş merkezi.alt kat no:13",
        addressTitle: "USDELIVERY",
        city: "ANKARA",
        state: "KIZILAY MAH",
        country: "Turkey",
        street: "KIZILAY MAH",
        district: "ÇANKAYA",
        postCode: "06050",
        phone: "05512801209",
    },
    china: {
        address: "大连市甘井子汇达街17号红星国际广场5号楼1416室",
        city: "Dalian (大连)",
        mark: "GLJY-air",
        postCode: "116003",
        phone: "13514117874",
        province: "Liaoning (辽宁)",
        district: "甘井子区 Ganjingzi"
    }
};

const MyPage = ({ setIsAdmin }) => {
    const lang = useSelector((state) => state.language.language);
    const [keyword, setKeyword] = useState("");
    const [parcels, setParcels] = useState([]);
    const [selectedDebt, setSelectedDebt] = useState(0);
    const [delivery, setDelivery] = useState({
        address: "",
        parcels: [],
        email: "",
        userId: ""
    });
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);
    const [showDeclarationModal, setShowDeclarationModal] = useState(false);
    const [total, setTotal] = useState(0);
    const [pageChanged, setPageChanged] = useState(1);
    const [status, setStatus] = useState("all");
    const [selectedAddress, setSelectedAddress] = useState("usa");
    const [showPaymentsPopup, setShowPaymentsPopup] = useState(false);
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });
    const [viewMode, setViewMode] = useState(false);
    const [activeParcel, setActiveParcel] = useState({});
    const [parcelForm, setParcelForm] = useState({
        tracking: "",
        price: '',
        currency: "",
        country: "",
        website: "",
        description: "",
        weight: "",
        file: null,
        debt: 0
    });
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setIsAdmin(false)
    }, [setIsAdmin])

    useEffect(() => {
        axios(`/api/parcels?limit=10&page=${pageChanged}&status=${status}&keyword=${keyword}`)
            .then((response) => {
                setTotal(response.data.totalCount)
                const data = response.data.data;
                setParcels([...data]);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageChanged, status, keyword])

    const declarateParcel = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', parcelForm.file);
        formData.append('tracking', parcelForm.tracking);
        formData.append('currency', parcelForm.currency);
        formData.append('price', parcelForm.price);
        formData.append('website', parcelForm.website);
        formData.append('description', parcelForm.description);
        formData.append('country', parcelForm.country);
        formData.append('weight', parcelForm.weight);
        formData.append('delivery', parcelForm.delivery);

        axios.put(`/api/parcels/declaration/${activeParcel.tracking}`, formData)
            .then((res) => {
                const data = parcels.map((p) => {
                    if (p.tracking === activeParcel.tracking) {
                        return { ...p, currency: parcelForm.currency, isDeclared: 1, price: parcelForm.price, website: parcelForm.website, description: parcelForm.description }
                    }
                    return { ...p }
                })
                setParcels([...data])
                setShowDeclarationModal(false)
            })
            .catch((err) => {
                const message = err.response.data.message !== "undefined" ? err.response.data.message.En : "";
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "Close"
                })
            })
    }

    const deliveryPayment = () => {
        axios.post('/api/payments/pay-delivery', {date: new Date()})
            .then((res) => {
                window.location.href = res.data.data.url;
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "Close"
                })
            })
    }

    const createPayment = () => {
        axios.post('/api/payments/pay-all-debt', { date: new Date() })
            .then((res) => {
                window.location.href = res.data.data.url;
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "Close"
                })
            })
    }

    const paySelected = () => {
        axios.post('/api/payments/pay-selected', { date: new Date(), debt: selectedDebt, parcels: selected })
            .then((res) => {
                window.location.href = res.data.data.url;
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "Close"
                })
            })
    }

    const searchByKeyword = (e) => {
        if (e.keyCode === 13) {
            axios(`/api/parcels?limit=10&page=${pageChanged}&status=${status}&keyword=${keyword}`)
                .then((response) => {
                    setTotal(response.data.totalCount)
                    const data = response.data.data;
                    setParcels([...data]);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const saveDelivery = (e) => {
        e.preventDefault();
        if (delivery.parcels.length === 0) {
            setNotification({
                show: true,
                header: "შეტყობინება",
                body: "გზავნილი არ არის მონიშნული",
                button: "დახურვა"
            });
        } else {
            axios.post('/api/parcels/delivery', delivery, { contentType: "application/json" })
                .then((res) => {
                    setNotification({
                        show: true,
                        header: "მიროდების სერვისი",
                        body: "თქვენი შეკვეთა მიღებულია",
                        button: "დახურვა"
                    });
                    setShowDeliveryModal(false)
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "Close"
                    })
                })
        }
    }

    return (
        <>
            <p className="section-titles" style={{ marginTop: "0px" }}>{languages[lang].titles.myPage.parcels}</p>
            <div className="table-header-container">
                <select className="form-control" style={{ maxWidth: "170px" }} onChange={(e) => setStatus(e.target.value)}>
                    <option value={"all"}>{languages[lang].titles.myPage.parcelTypes.all}</option>
                    <option value={"საწყობში"}>{languages[lang].titles.myPage.parcelTypes.received}</option>
                    <option value={"გზაშია"}>{languages[lang].titles.myPage.parcelTypes.sent}</option>
                    <option value={"ჩამოსულია"}>{languages[lang].titles.myPage.parcelTypes.arrived}</option>
                    <option value={"გატანილი"}>{languages[lang].titles.myPage.parcelTypes.takenOut}</option>
                </select>
                <div className="search-keyword-container">
                    <input type="text" onKeyDown={searchByKeyword} placeholder={languages[lang].titles.myPage.keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control" style={{ maxWidth: "400px" }} />
                </div>
                <div>
                    <button className="pay-button" onClick={() => {
                        setShowDeliveryModal(true);
                        setDelivery((prev) => {
                            return { ...prev, address: "" }
                        })
                    }} style={{ marginRight: "10px" }}>მიწოდება</button>
                    <button className="pay-button" onClick={() => setShowPaymentsPopup(true)}>{languages[lang].titles.myPage.pay}</button>
                </div>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th></th>
                        <th>{languages[lang].titles.myPage.tracking}</th>
                        <th>{languages[lang].titles.myPage.status}</th>
                        <th>{languages[lang].titles.myPage.payed}</th>
                        <th>{languages[lang].titles.myPage.declared}</th>
                        <th>{languages[lang].titles.myPage.flightId}</th>
                        <th>{languages[lang].titles.myPage.weight}</th>
                        <th>{languages[lang].titles.myPage.debt}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {parcels.map((item) => (
                        <tr key={item.tracking}>
                            <td><input type="checkbox" onChange={(e) => {
                                setParcelForm((prev) => {
                                    if (e.target.checked) {
                                        return { ...prev, debt: parseFloat(prev.debt) + parseFloat(item.debt) }
                                    } else {
                                        return { ...prev, debt: parseFloat(prev.debt) - parseFloat(item.debt) }
                                    }
                                })

                                setDelivery((prev) => {
                                    if (e.target.checked) {
                                        return { ...prev, email: item.receiver, userId: item.userId, parcels: [...prev.parcels, item.tracking] }
                                    } else {
                                        const data = prev.parcels.filter((pr) => pr !== item.tracking);
                                        return { ...prev, email: item.receiver, userId: item.userId, parcels: [...data] }
                                    }
                                })

                                setSelected((prev) => {
                                    if (e.target.checked) {
                                        setSelectedDebt((prev) => prev + parseFloat(item.debt))
                                        return [...prev, item.tracking];
                                    } else {
                                        const data = prev.filter((pr) => pr !== item.tracking);
                                        setSelectedDebt((prev) => prev - parseFloat(item.debt))
                                        return [...data]
                                    }
                                })

                            }} /></td>
                            <td>{item.tracking}</td>
                            <td>{item.status}</td>
                            <td>{item.isPayed === 1 ? <img src="/static/image/check.png" className="table-icon" alt='icon' /> : <img className="table-icon" src="/static/image/cross.png" alt='icon' />}</td>
                            <td>{item.isDeclared === 1 ? <img src="/static/image/check.png" className="table-icon" alt='icon' /> : <img className="table-icon" src="/static/image/cross.png" alt='icon' />}</td>
                            <td>{item.flightId}</td>
                            <td>{item.weight}</td>
                            <td>{item.debt}</td>
                            <td >
                                <button onClick={() => {
                                    setViewMode(true);
                                    setActiveParcel({ ...item })
                                }} title='View parcel' className="none-button"><img style={{ width: "20px" }} src="/static/image/eye.svg" alt="actions" /></button>
                                <button onClick={() => {
                                    setShowDeclarationModal(true);
                                    setActiveParcel({ ...item });
                                    setParcelForm({
                                        tracking: item.tracking,
                                        price: item.price,
                                        currency: item.currency,
                                        country: item.country,
                                        website: item.website,
                                        description: item.description,
                                        weight: item.weight
                                    })
                                }} title='Declarate parcel' className="none-button"><img style={{ width: "20px" }} src="/static/image/edit.svg" alt="actions" /></button>
                            </td>
                        </tr>
                    ))}
                    {
                        parcels.length === 0 && (
                            <tr className="blank_row">
                                <td colSpan="10" style={{ textAlign: "center" }}>No items found</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return ''
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>

            <DeliveryList />

            <p className="section-titles" >{languages[lang].titles.myPage.addresses}</p>
            <Addresses addressSelector={setSelectedAddress} active={selectedAddress} />
            <SelectedAddress location={addresses[selectedAddress]} country={selectedAddress} />
            <Modal show={viewMode} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setViewMode(!viewMode)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>View parcel</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="view-parcel-modal">
                        <div className="view-parcel-col">
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>თრექინგი:</p><p style={{ color: "white" }}>{activeParcel.tracking}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>ვალუტა:</p><p style={{ color: "white" }}>{activeParcel.currency}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>მიმღები:</p><p style={{ color: "white" }}>{activeParcel.userId}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>ვებსაიტი:</p><p style={{ color: "white" }}>{activeParcel.website}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>სახიფათო:</p><p style={{ color: "white" }}>{activeParcel.dangerous === 0 ? "არა" : "კი"}</p></div>
                            <div className="view-parcel-item bottom"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>რეისის ნომერი:</p><p style={{ color: "white" }}>{activeParcel.flightId}</p></div>
                        </div>
                        <div className="view-parcel-col">
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>ფასი:</p><p style={{ color: "white" }}>{activeParcel.price}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>სტატუსი:</p><p style={{ color: "white" }}>{activeParcel.status}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>ქვეყანა:</p><p style={{ color: "white" }}>{activeParcel.country}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>მიღების მისამართი:</p><p style={{ color: "white" }}>{activeParcel.parcelReceiveAddress}</p></div>
                            <div className="view-parcel-item"><p style={{ color: "white", width: "154px", marginRight: "20px", fontWeight: "bold" }}>აღწერა:</p><p style={{ color: "white" }}>{activeParcel.description}</p></div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setViewMode(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeliveryModal} centered size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setShowDeliveryModal(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>მიწოდების სერვისი</h3>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={saveDelivery}>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">მისამართი</label>
                            <input type="text" placeholder="ჩაწერეთ მიწოდების მისამართი" className="form-control" value={delivery.address} onChange={(e) => setDelivery((prev) => ({ ...prev, address: e.target.value }))} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">თარიღი</label>
                            <input type="date" className="form-control" value={delivery.date} onChange={(e) => setDelivery((prev) => ({ ...prev, date: e.target.value }))} required />
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">კომენტარი</label>
                            <textarea style={{ height: "90px !important" }} placeholder="დაამატეთ კომენტარი" className="form-control comments-textarea" value={delivery.comment} onChange={(e) => setDelivery((prev) => ({ ...prev, comment: e.target.value }))} />
                        </div>
                        <div className="col-md-12 submit-form-buttons">
                            <Button variant="primary" onClick={() => setShowDeliveryModal(false)}>დახურვა</Button>
                            <Button variant="danger" type="submit" style={{ marginLeft: "20px" }}>შენახვა</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={showPaymentsPopup} size="lg" centered area-labelledby="contained-modal-title-vcenter" onHide={() => setShowPaymentsPopup(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>გადახდა</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment-buttons">
                        <Button variant="info" className="pay-all-button" onClick={() => {
                            createPayment()
                        }}>
                            ყველას გადახდა
                        </Button>
                        <Button variant="primary" className="pay-all-button" onClick={() => {
                            deliveryPayment()
                        }}>
                            მიწოდების საფასური
                        </Button>
                        <Button variant="danger" onClick={() => {
                            paySelected()
                        }}>
                            მონიშნულის გადახდა
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowPaymentsPopup(false)}>დახურვა</Button>
                </Modal.Footer>
            </Modal>
            <Declaration show={showDeclarationModal} hide={setShowDeclarationModal} saveHandler={declarateParcel} data={parcelForm} dataSetter={setParcelForm} />
        </>
    )
}

export default MyPage