import { useEffect } from "react"
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min"
import Dashboard from "./Dashboard"
import AdminFlights from "./Flights"
import Users from "./Users"
import Parcels from "./Parcels"
import Delivery from "./Delivery"
import Market from "./Market"

const AdminRoute = ({setIsAdmin}) => {
    useEffect(() => {
        setIsAdmin(true)
    }, [setIsAdmin])
    return(
        <Switch>
            <Route path='/admin/dashboard'>
                <Dashboard />
            </Route>
            <Route path='/admin/delivery'>
                <Delivery />
            </Route>
            <Route path='/admin/flights'>
                <AdminFlights />
            </Route>
            <Route path='/admin/users'>
                <Users />
            </Route>
            <Route path='/admin/parcels'>
                <Parcels />
            </Route>
            <Route path='/admin/markets'>
                <Market />
            </Route>
        </Switch>
    )
}

export default AdminRoute