import { Link } from "react-router-dom/cjs/react-router-dom.min"
import './Services.css'
const Service = ({ image, title, url }) => {
    return (
        <Link to={url}>
            <div className="service-box">
                <img src={image} className="services-icon" alt='service' />
                <p className="services-title">{title}</p>
            </div>
        </Link>
    )
}

export default Service