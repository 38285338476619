import { configureStore } from '@reduxjs/toolkit';
import language from './language';
import user from './user';

const store = configureStore({
    reducer: {
        language: language,
        user: user
    }
});




export default store;