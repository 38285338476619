import {useEffect, useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import axios from "axios";

const Market = () => {
    const [markets, setMarkets] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [showDeleteMarket, setShowDeleteMarket] = useState(false);
    const [active, setActive] = useState({});
    const [action, setAction] = useState("");
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({show: false, body: "", header: "", button: ""});
    const [showAddNewMarket, setShowAddNewMarket] = useState(false);
    const [formData, setFormData] = useState({name: "", country: "", url: "", icon: ""});
    const [limit, setLimit] = useState(10);
    const [pageReviewCount, setPageRenewCount] = useState(0);
    useEffect(() => {
        axios(`/api/markets?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((res) => {
                setTotal(res.data.totalCount);
                console.log(res.data);
                setMarkets(res.data.data);
            })
            .catch((err) => {
                const message = err.response.data.message;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }, [pageReviewCount]);

    const addNewMarket = () => {
        axios.post('/api/market', formData)
            .then(() => {
                setShowAddNewMarket(false);
                setNotification({
                    show: true,
                    header: "Success",
                    body: "მაღაზია დამატებულია",
                    button: "დახურვა"
                });
                setPageRenewCount((prev) => prev + 1);
            })
            .catch((err) => {
                const message = err.response.data.message;
                const description = err.response.data.description;
                setShowAddNewMarket(false);
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const editMarket = () => {
        axios.put(`/api/market/${active.id}`, formData)
            .then(() => {
                setShowAddNewMarket(false);
                setNotification({
                    show: true,
                    header: "Success",
                    body: "შენახულია",
                    button: "დახურვა"
                })
                setPageRenewCount((prev) => prev + 1);
            })
            .catch((err) => {
                const message = err.response.data.message;
                const description = err.response.data.description;
                setShowAddNewMarket(false);
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const deleteMarket = () => {
        axios.delete(`/api/market/${active.id}`)
            .then(() => {
                setShowAddNewMarket(false);
                setNotification({
                    show: true,
                    header: "Success",
                    body: "მაღაზია წაშლილია",
                    button: "დახურვა"
                })
                setPageRenewCount((prev) => prev + 1);
            })
            .catch((err) => {
                const message = err.response.data.message;
                const description = err.response.data.description;
                setShowAddNewMarket(false);
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    return (
        <>
            <div className="admin-flights-head">
                <div className="search-keyword-container">
                    <input type="text" placeholder="ჩაწერეთ საძიებო სიტყვა"
                           className="form-control"
                           style={{maxWidth: "400px"}}/>
                </div>
                <button className="pay-button" onClick={() => {
                    setShowAddNewMarket(true);
                    setFormData({name: "", country: "", url: "", icon: ""});
                    setAction("add");
                }}>დამატება
                </button>
            </div>
            <Table responsive>
                <thead>
                <tr>
                    <th>დასახელება</th>
                    <th>ქვეყანა</th>
                    <th>URL</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    markets.map((m) => {
                        return (
                            <tr key={m.id}>
                                <td>{m.name}</td>
                                <td>{m.country}</td>
                                <td>{m.url}</td>
                                <td>
                                    <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                                        <button className={"none-button"} onClick={() => {
                                            setActive({...m});
                                            setFormData({name: m.name, country: m.country, url: m.url, icon: m.icon});
                                            setShowAddNewMarket(true);
                                            setAction("edit");
                                        }}>
                                            <img style={{width: "20px"}} src="/static/image/edit.svg" alt="actions"/>
                                        </button>
                                        <button className={"none-button"} onClick={() => {
                                            setActive({...m});
                                            setShowDeleteMarket(true);
                                        }}>
                                            <img style={{width: "20px"}}
                                                 src="https://cdn-icons-png.flaticon.com/512/484/484662.png"
                                                 alt="actions"/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
            <div className="pagination-container">
                {page > 1 && <span className="next-page-button" onClick={() => {
                    setPage((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < page - 2 || p + 1 > page + 2) {
                                return
                            } else {
                                return (<span key={k * 15}
                                              className={(page === p + 1) ? "active-page-number" : undefined}
                                              onClick={() => {
                                                  setPage(p + 1)
                                              }
                                              }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {page < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPage((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>
            <Modal show={showAddNewMarket} onHide={() => setShowAddNewMarket(false)}>
                <Modal.Header><h3 style={{color: "white"}}>მაღაზიის დამატება</h3></Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">დასახელება</label>
                            <input type="text" className="form-control" value={formData.name}
                                   onChange={(e) => setFormData((prev) => ({...prev, name: e.target.value}))}
                                   placeholder="ჩაწერეთ მაღაზიის სახელი" required/>
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">მაღაზიის ლინკი</label>
                            <input type="text" className="form-control" value={formData.url}
                                   onChange={(e) => setFormData((prev) => ({...prev, url: e.target.value}))}
                                   placeholder="ჩაწერეთ მაღაზიის ლინკი" required/>
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">მაღაზიის ლოგო</label>
                            <input type="text" className="form-control" value={formData.icon}
                                   onChange={(e) => setFormData((prev) => ({...prev, icon: e.target.value}))}
                                   placeholder="ჩასვით icon-ის ლინკი" required/>
                        </div>
                        <div className="form-group">
                            <label className="mt-3 mb-1 color-white">ქვეყანა</label>
                            <select className="form-control" value={formData.country}
                                    onChange={(e) => setFormData((prev) => ({...prev, country: e.target.value}))}
                                    required>
                                <option value="">ქვეყანა</option>
                                <option value="usa">ამერიკა</option>
                                <option value="turkey">თურქეთი</option>
                                <option value="poland">პოლონეთი</option>
                                <option value="greece">საბერძნეთი</option>
                                <option value="china">ჩინეთი</option>
                            </select>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        {action === "add" && <button onClick={addNewMarket}>დამატება</button>}
                        {action === "edit" && <button onClick={editMarket}>შენახვა</button>}
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter"
                   onHide={() => setNotification((prev) => ({...prev, show: false}))}>
                <Modal.Header>
                    <h3 style={{color: "white"}}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{color: "white"}}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteMarket} size="md" area-labelledby="contained-modal-title-vcenter"
                   onHide={() => setShowDeleteMarket(false)}>
                <Modal.Header>
                    <h3 style={{color: "white"}}>მაღაზიის წაშლა</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{color: "white"}}>ნამდვილად გსურთ {active.name} წაშლა?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        setShowDeleteMarket(false);
                        deleteMarket();
                    }}>დიახ</Button>
                    <Button variant="primary" onClick={() => setShowDeleteMarket(false)}>არა</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Market