import React, {useEffect} from "react";
import languages from "../../data/languages";
import {useSelector} from "react-redux";

const Terms = ({activePageSetter}) => {
    const lang = useSelector((state) => state.language.language);

    useEffect(() => {
        activePageSetter("terms")
    }, [activePageSetter]);
    return(
        <div className={'content'} style={{alignItems: "flex-start"}}>
            <div className={'banner-image-container'}>
                <img src={`/static/image/customs.svg`} alt="bonus points " className={'banner-image'}/>
            </div>
            <div className={"container"}>
                <p className={'title'} style={{marginTop: "0px", paddingTop: "30px"}}>{languages[lang].servicesPages.usefulInformation.customsProcedure.title}</p>
                <ul className={'services'}>
                    {languages[lang].servicesPages.usefulInformation.customsProcedure.content.map((p, k) => {
                        return <li key={k * 11} className={'text'}>{p}</li>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Terms