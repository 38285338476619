import { useEffect, useState } from "react"
import Banner from "../Components/MainPage/Banner/Banner"
import Service from "../Components/MainPage/Services/Service"
import FlightsSlider from "../Components/MainPage/flightsSlider/FlightsSlider"
import languages from "../data/languages"
import { useSelector } from "react-redux";

const Main = ({ setIsAdmin }) => {
    const lang = useSelector((state) => state.language.language);
    const [calculator, setCalculator] = useState({
        height: "",
        width: "",
        length: "",
        value: "",
        weight: ""
    });
    const [weight, setWeight] = useState("");

    const calculateWeight = () => {
        const width = calculator.width === "" ? 0 : calculator.width;
        const height = calculator.height === "" ? 0 : calculator.height;
        const length = calculator.length === "" ? 0 : calculator.length;

        const k = width * height * length;
        const v = k / 6000;
        setWeight(v);
        const value = v * 10.9;
        setCalculator((prev) => ({ ...prev, value: value.toFixed(4) }))
    }

    useEffect(() => {
        setIsAdmin(false);
    }, [setIsAdmin])
    return (
        <>
            <Banner />
            <p className="section-titles">{languages[lang].mainPage.services_title}</p>
            <div className="services-container">
                <Service image={`/static/image/parcel-icon.svg`} title={languages[lang].mainPage.online_parcels} url="/services/online-parcels" />
                <Service image={`/static/image/howtobuy.svg`} title={languages[lang].mainPage.help} url="/usefull-information/how-to-buy" />
                <Service image={`/static/image/shipping.svg`} title={languages[lang].mainPage.delivery} url="/services/delivery-service" />
                <Service image={`/static/image/bonus.svg`} title={languages[lang].mainPage.bonus} url="/services/bonus" />
            </div>
            <p className="section-titles">{languages[lang].mainPage.flights}</p>
            <FlightsSlider />
            <p className="section-titles">{languages[lang].mainPage.office}</p>
            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2977.774355632346!2d44.7422209!3d41.7253877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4044731aa372bf73%3A0x23659c815a9108bc!2zNDcg4YOV4YOQ4YOf4YOQLeGDpOGDqOGDkOGDleGDlOGDmuGDkOGDoSDhg5Lhg5Dhg5vhg5bhg5jhg6Dhg5gsIOGDl-GDkeGDmOGDmuGDmOGDoeGDmA!5e0!3m2!1ska!2sge!4v1724065323133!5m2!1ska!2sge"
                    className="map" style={{ border: "0" }} allowFullScreen="" loading="lazy" title="map"
                    referrerPolicy="no-referrer-when-downgrade" > </iframe>
            </div>
        </>
    )
}

export default Main