import axios from "axios"
import { useEffect, useState } from "react"
import { Modal, Button, Table } from "react-bootstrap"
// import CiRedo from "react-icons/ci"

const AdminFlights = () => {
    const [flights, setFlights] = useState([]);
    const [pageChanged, setPageChanged] = useState(1);
    const [total, setTotal] = useState(0);
    const [showAddFlightsModal, setShowAddFlightsModal] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [actionType, setActionType] = useState("add");
    const [flightStatus, setFlightStatus] = useState(false);
    const [deleteFlight, setDeleteFlight] = useState(false);
    const [status, setStatus] = useState("");
    const [formData, setFormData] = useState({
        status: "საწყობში",
        country: "",
        flightId: "",
        estimatedArrivalDate: "",
        takeOffDate: "",
        comments: ""
    });
    const [showDangerDialog, setShowDangerDialog] = useState(false);
    const [active, setActive] = useState("");
    const [notification, setNotification] = useState({
        show: false,
        header: "",
        body: "",
        button: ""
    });

    useEffect(() => {
        axios(`/api/flights?limit=10&page=${pageChanged}&keyword=${keyword}`)
            .then((res) => {
                const data = res.data.data;
                setFlights([...data]);
                setTotal(res.data.totalCount)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [pageChanged]);

    const deleteFlightHandler = () => {
        axios.delete(`/api/flight/${active}`)
            .then(() => {
                const edit = flights.filter((f) => f.flightId !== active);
                setFlights([...edit]);
                setDeleteFlight(false);
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setDeleteFlight(false);
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const changeFlightStatus = () => {
        axios.put(`/api/flight/${active}/status`, { status: status })
            .then((res) => {
                const edit = flights.map((f) => {
                    if (f.flightId === active) {
                        return { ...f, status: status };
                    } else {
                        return { ...f }
                    }
                });
                setFlights([...edit]);
                setFlightStatus(false)
            })
            .catch((err) => {
                const message = err.response.data.message.En;
                const description = err.response.data.description;
                setNotification({
                    show: true,
                    header: description,
                    body: message,
                    button: "დახურვა"
                })
            })
    }

    const markAsDanger = () => {
        axios.put(`/api/status/danger`, { flight: active })
            .then((res) => {
                alert("Success");
                setShowDangerDialog(false)
            })
            .catch(() => {
                alert("Something went wrong")
                setShowDangerDialog(false)
            })
    }

    const invoice = (id) => {
        axios.post(`/api/export/excel/${id}`)
            .then((response) => {
                const url = response.data.url;
                window.open(url);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const addFlight = (e) => {
        e.preventDefault();
        if (actionType === "add") {
            axios.post('/api/flights', formData)
                .then((res) => {
                    setShowAddFlightsModal(false);
                    setNotification({
                        show: true,
                        header: "რეისის დამატება",
                        body: "რეისი დამატებულია",
                        button: "Close"
                    });

                    axios(`/api/flights?limit=10&page=${pageChanged}&keyword=${keyword}`)
                        .then((res) => {
                            const data = res.data.data;
                            setFlights([...data]);
                            setTotal(res.data.totalCount)
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        }

        if (actionType === "edit") {
            axios.put(`/api/flights/${active.Id}`, formData)
                .then((res) => {
                    setShowAddFlightsModal(false);
                    setNotification({
                        show: true,
                        header: "რეისის რედაქტირება",
                        body: "რეისი რედაქტირებულია",
                        button: "დახურვა"
                    });

                    axios(`/api/flights?limit=10&page=${pageChanged}&keyword=${keyword}`)
                        .then((res) => {
                            const data = res.data.data;
                            setFlights([...data]);
                            setTotal(res.data.totalCount)
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                })
                .catch((err) => {
                    const message = err.response.data.message.En;
                    const description = err.response.data.description.En;
                    setNotification({
                        show: true,
                        header: description,
                        body: message,
                        button: "დახურვა"
                    })
                })
        }
    }

    const searchByKeyword = (e) => {
        if (e.keyCode === 13) {
            axios(`/api/flights?page=${pageChanged}&limit=10&keyword=${keyword}`)
                .then((response) => {
                    setTotal(response.data.totalCount)
                    const data = response.data.data;
                    setFlights([...data]);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <>
            <div className="admin-flights-head">
                <div className="search-keyword-container">
                    <input type="text" onKeyDown={searchByKeyword} placeholder="ჩაწერეთ საძიებო სიტყვა" onChange={(e) => setKeyword(e.target.value)} className="form-control" style={{ maxWidth: "400px" }} />
                </div>
                <button className="pay-button" onClick={() => {
                    setShowAddFlightsModal(true);
                    setActionType("add");
                    setFormData({
                        status: "საწყობში",
                        country: "",
                        flightId: "",
                        estimatedArrivalDate: "",
                        takeOffDate: "",
                        comments: ""
                    })
                }}>Add Flight</button>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                        <th>სტატუსი</th>
                        <th>რეისის აიდი</th>
                        <th>გამოსვლის დრო</th>
                        <th>სავარაუდო ჩამოსვლის დრო</th>
                        <th>ქვეყანა</th>
                        <th>კომენტარი</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        flights.map((f) => {
                            return (
                                <tr key={f.flightId}>
                                    <td>{f.status}</td>
                                    <td>{f.flightId}</td>
                                    <td>{f.estimatedArrivalDate}</td>
                                    <td>{f.takeOffDate}</td>
                                    <td>{f.country}</td>
                                    <td>{f.comments}</td>
                                    <td>
                                        <button title='Edit flight' onClick={() => {
                                            setActionType("edit");
                                            setShowAddFlightsModal(true);
                                            setFormData({
                                                status: f.status,
                                                flightId: f.flightId,
                                                estimatedArrivalDate: f.estimatedArrivalDate,
                                                takeOffDate: f.takeOffDate,
                                                country: f.country,
                                                comments: f.comments
                                            });
                                            setActive(f);
                                        }} className="none-button"><img style={{ width: "20px" }} src="/static/image/edit.svg" alt="actions" /></button>
                                        <button title='Change status' onClick={() => {
                                            setFlightStatus(true);
                                            setActive(f.flightId);
                                        }} className="none-button"><img style={{ width: "20px" }} src="/static/image/change.svg" alt="actions" /></button>
                                        <button title='Download' onClick={() => {
                                            invoice(f.flightId)
                                        }} className="none-button"><img style={{ width: "20px" }} src="/static/image/download.svg" alt="actions" /></button>
                                        <button title='Delete flight' onClick={() => {
                                            setDeleteFlight(true);
                                            setActive(f.flightId);
                                        }} className="none-button"><img style={{ width: "20px" }} src="https://cdn-icons-png.flaticon.com/512/484/484662.png" alt="actions" /></button>
                                        <button title='Danger' onClick={() => {
                                            setActive(f.flightId);
                                            setShowDangerDialog(true);
                                        }} className="none-button"><img style={{ width: "20px" }} src="/static/image/danger.svg" alt="actions" /></button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <div className="pagination-container">
                {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev - 1))
                }}>
                    {'<<'}
                </span>}
                {
                    Array.from(Array(Math.ceil(total / 10)).keys())
                        .map((p, k) => {
                            if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                return
                            } else {
                                return (<span key={k * 15} className={(pageChanged === p + 1) ? "active-page-number" : undefined} onClick={() => {
                                    setPageChanged(p + 1)
                                }
                                }>
                                    {p + 1}
                                </span>)
                            }
                        })
                }
                {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                    setPageChanged((prev) => (prev + 1))
                }}>
                    {'>>'}
                </span>}
            </div>
            <Modal show={showAddFlightsModal} size="lg" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setShowAddFlightsModal(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>რეისის დამატება</h3>
                </Modal.Header>
                <Modal.Body>
                    <form className="container" style={{ paddingTop: "0px" }} onSubmit={addFlight}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">Name</label>
                                    <select className="form-control" value={formData.country} onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))} required >
                                        <option value="">ქვეყანა</option>
                                        <option value="usa">ამერიკა</option>
                                        <option value="turkey">თურქეთი</option>
                                        <option value="poland">პოლონეთი</option>
                                        <option value="greece">საბერძნეთი</option>
                                        <option value="china">ჩინეთი</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">რეისის ნომერი</label>
                                    <input type="text" className="form-control" value={formData.flightId} onChange={(e) => setFormData((prev) => ({ ...prev, flightId: e.target.value }))} placeholder="შეიყვანეთ რეისის ნომერი" required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">გამოსვლის თარიღი</label>
                                    <input type="date" className="form-control" value={formData.estimatedArrivalDate} onChange={(e) => setFormData((prev) => ({ ...prev, estimatedArrivalDate: e.target.value }))} required />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">სავარაუდო ჩამოსვლის დრო</label>
                                    <input type="date" className="form-control" value={formData.takeOffDate} onChange={(e) => setFormData((prev) => ({ ...prev, takeOffDate: e.target.value }))} required />
                                </div>
                                <div className="form-group">
                                    <label className="mt-3 mb-1 color-white">კომენტარი</label>
                                    <textarea type="text" rows={"5"} className="form-control textarea" value={formData.comments} onChange={(e) => setFormData((prev) => ({ ...prev, comments: e.target.value }))} placeholder="დაწერეთ კომენტარი" />
                                </div>
                            </div>
                            <div className="col-md-12 submit-form-buttons">
                                <Button variant="primary" onClick={() => setShowAddFlightsModal(false)}>დახურვა</Button>
                                <Button variant="danger" type="submit" style={{ marginLeft: "20px" }}>{actionType === "add" ? "დამატება" : "შენახვა"}</Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={flightStatus} size="md" area-labelledby="contained-modal-title-vcenter" centered onHide={() => setFlightStatus(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>Change status</h3>
                </Modal.Header>
                <Modal.Body>
                    <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option>სტატუსი</option>
                        <option value={"საწყობში"}>საწყობში</option>
                        <option value={"გზაშია"}>გზაში</option>
                        <option value={"ჩამოსული"}>ჩამოსული</option>
                        <option value={"დასრულებული"}>დასრულებული</option>
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setFlightStatus(false)}>დახურვა</Button>
                    <Button variant="danger" onClick={changeFlightStatus}>შენახვა</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={notification.show} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>{notification.header}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>{notification.body}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setNotification((prev) => ({ ...prev, show: false }))}>{notification.button}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteFlight} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setDeleteFlight(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>რეისის წაშლა</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>ნამდვილად გსურთ რეისის წაშლა?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setDeleteFlight(false)}>არა</Button>
                    <Button variant="danger" onClick={deleteFlightHandler}>დიახ</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDangerDialog} size="md" area-labelledby="contained-modal-title-vcenter" onHide={() => setShowDangerDialog(false)}>
                <Modal.Header>
                    <h3 style={{ color: "white" }}>სახიფათო ტვირთად მონიშვნა</h3>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: "white" }}>ნამდვილად გსურთ მოქმედების შესრულება?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowDangerDialog(false)}>არა</Button>
                    <Button variant="danger" onClick={markAsDanger}>დიახ</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminFlights